import { Table, NavLink, Image, Jumbotron, Container, Row, Col, Badge, Card } from "react-bootstrap";
import styles from './SolutionInfo.css';
import Green_image from '../images/bg_green_wing_v03_crop.png';
import Gray_image from '../images/bg_gray_wing_v03_crop.png';
import { useTranslation } from 'react-i18next';

export default function SolutionInfo() {
  const { t } = useTranslation();
  
  return (
   <div className="solution_body">
    <Container>
      <Row>
        <Col xs={12} md={12}style={{padding:10, marginTop:50, marginBottom:50}}>
          <div><h1><Badge variant="secondary">{t('SOLUTION')}</Badge></h1></div>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={12} md={12} style={{marginBottom:50}}>
          <Image src={Gray_image} rounded width="200" height="200" />
        </Col>
      </Row>
      
    </Container>
  </div>
  );
}