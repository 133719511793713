import React, { useState, useEffect } from "react";
import "./RouteMap.css";
import {Form, Row, Col, ButtonGroup, Accordion, Card, Button, Badge} from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useHistory } from "react-router-dom";
import axiosInstance from "../axiosApi";
import { onError } from "../libs/errorLib";
import { useAppContext } from "../libs/contextLib";
import { FaStopCircle } from "react-icons/fa";
import moment from "moment";
import 'moment/locale/ko';
import { useTranslation } from 'react-i18next';
import DropdownSelector from "../components/DropdownSelector";

function StationComponent(props) {
    const [station, setStation] = useState([]);
    const { setRouteAppContext } = useAppContext();
    
    const { strStationAppContext } = useAppContext();
    const { setStrStationAppContext } = useAppContext();
    
    const { endStationAppContext } = useAppContext();
    const { setEndStationAppContext } = useAppContext();
    
    const [strStationOptions, setStrStationOptions] = useState([]);
    const [endStationOptions, setEndStationOptions] = useState([]);
    const [column, setColumn] = useState("");
    const { t } = useTranslation();
    
    useEffect(() => {
        function fetchStation() {
            setStrStationOptions([]);
            setEndStationOptions([]);
            try {
                axiosInstance.get('/robotaxi/routemap/',{
                    params: {id: props.routename.id}
                }).then(function(response) {
                    setStation(response.data);
                    setStrStationOptions(response.data.map( data => ( { name: data.station.stationname, id: data.station.id, station: data} ) ));
                    setEndStationOptions(response.data.map( data => ( { name: data.station.stationname, id: data.station.id, station: data} ) ));
                }).catch(function (error) {
                    //console.log(error);
                    if ( error.response.status === 444 ) {
                      onError(error.response.data.message);
                    }
                });
            } catch (e) {
                onError(e);
            }
        }
        fetchStation();
    }, []);
    
    const onClickHandler = (param, event) => {
        //console.log(history.location.pathname);
        setRouteAppContext(props.routename);
        if (column === "strStation" ) {
          if ( param !== endStationAppContext ) {
            setStrStationAppContext(param);
          }
        } 
        
        if (column === "endStation" ) {
          if ( param !== strStationAppContext ) {
            setEndStationAppContext(param);
          }
        }
        //history.push("/bookingstation");
    };
    
    const onClickEndStation = () => {
        setColumn("endStation");
    };
    
    const onClickStrStation = () => {
        setColumn("strStation");
    };
    
    function onChangeStrStation(event) {
      const result = strStationOptions.find(option => Number(option.id) === Number(event.target.value));
      if (result && result.station !== endStationAppContext) {
        setStrStationAppContext(result.station);
      } else {
        setStrStationAppContext("");
      }
    }

    function onChangeEndStation(event) {
      const result = strStationOptions.find(option => Number(option.id) === Number(event.target.value));
      if (result && result.station !== strStationAppContext) {
        setEndStationAppContext(result.station);
      } else {
        setEndStationAppContext("");
      }
    }

    return (
       <div className="bg-white">
         <Form className="form">
            <Row className="mx-1 my-1">
                <Col sm={6} >
                    <Form.Group as={Row} controlId="formHorizontal">
                        <Col className="my-1" xs={4} md={4}>
                            <Form.Label>{t('DEPART')}</Form.Label>
                        </Col>
                        <Col className="my-1" xs={8} md={8}>
                            <DropdownSelector
                                labelName=""
                                options={strStationOptions}
                                defaultValue={strStationAppContext === '' ? '' : strStationAppContext.station.id}
                                onChange={onChangeStrStation}
                            />
                        </Col>
                    </Form.Group>
                </Col>
                <Col sm={6} >
                    <Form.Group as={Row} controlId="formHorizontal">
                        <Col className="my-1" xs={4} md={4}>
                            <Form.Label>{t('ARRIVAL')}</Form.Label>
                        </Col>
                        <Col className="my-1" xs={8} md={8}>
                            <DropdownSelector
                                labelName=""
                                options={endStationOptions}
                                defaultValue={endStationAppContext === '' ? '' : endStationAppContext.station.id}
                                onChange={onChangeEndStation}
                            />
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
            {/*
            <div className="pt-2 pb-2">
            {station.map((data) => {
              return (
                <>
                  <Button size="sm" className="RouteButton" variant="dark" onClick={(e) => onClickHandler(data,e)}>
                    <FaStopCircle />{' '}{data.station.stationname}{' '}<Badge bg="dark">{data.sequence}</Badge>
                  </Button>{' '}
                </>
              );
            })}
            </div>
          */}
        </Form>
      </div>
    );
}

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log('totally custom!'),
  );

  return (
    <button
      type="button"
      style={{ backgroundColor: 'pink' }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

function RouteMapDisplay(props) {
    const [routename, setRoutename] = useState([]);
    const { t } = useTranslation();
    
    return (
      <Accordion defaultActiveKey="1" flush className="Route">
        {props.route.map((data) => {
            return (
          <Card className="Card">
          <Card.Header className="header">
            <div className="mx-3">
              {data.routename}
            </div>
          </Card.Header>

          <Accordion.Collapse eventKey="1">
            <Card.Body className="body">
                <StationComponent routename={data} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
          );
        })}
      </Accordion>
    );
}

export default RouteMapDisplay;