import { createRef, useContext } from 'react';
import FancyButton from './FancyButton';
import LayoutContext from './LayoutContext';
import styles from './Navigation.module.css';
import ScrollSpy from 'react-scrollspy-navigation';
import { Nav, Navbar, NavItem, NavDropdown } from "react-bootstrap";
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

export default function Navigation() {
  const [isColumn, setIsColumn] = useContext(LayoutContext);
  const toggleSuffix = isColumn ? 'Horizontal' : 'Vertical';
  const { t } = useTranslation();

  function onClickToggleLayout(e) {
    e.preventDefault();
    setIsColumn(!isColumn);
  }

  return (
    <Navbar bg="light" sticky="top" className={styles.container}>
      <ScrollSpy>
        <FancyButton href="#box-1" text={t('ABOUTUS')} ref={createRef()} />
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <FancyButton href="#box-2" text={t('BUSINESSAREAS')} ref={createRef()} />
            <FancyButton href="#box-3" text={t('SOLUTION')} ref={createRef()} />
            <FancyButton href="#box-6" text={t('CONTACT')} ref={createRef()} />
          </Nav>
        </Navbar.Collapse>
      </ScrollSpy>
    </Navbar>
    
  );
}
