import React, { useRef, useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import styled, { ThemeConsumer } from "styled-components";
import { getYear, getMonth } from "date-fns"; // getYear, getMonth 
import DatePicker, { registerLocale } from "react-datepicker";  // 한국어적용
import ko from 'date-fns/locale/ko'; // 한국어적용
import { FaSearch, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import axiosInstance from "../axiosApi";
import * as dateFns from "date-fns";
import { onError } from "../libs/errorLib";

registerLocale("ko", ko) // 한국어적용
const _ = require('lodash');

const Wrap = styled.div`
  .saturday { color: rgb(0, 0, 255); } 
  .sunday { color: rgb(255, 0, 0); } 
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    background-color: #E9ECEF;
    border-color: transparent;
    width: 100%;
    height: 100%;
    font-size: 16px;
  }
`;

export default function TableDateTimeCommon(props) {
 const { t } = useTranslation();
 const [startDate, setStartDate] = useState(new Date());
 const [minTime, setMinTime] = useState(new Date());
 const [maxTime, setMaxTime] = useState(new Date());
 const [isHoliday, setIsHoliday] = useState(false);
 const [excludeTimes, setExcludeTimes] = useState([]);
 const [excludeDates, setExcludeDates] = useState([]);
  
 useEffect(() => {
    async function onLoad() {
      try {
        // exclude times
        axiosInstance.get('/robotaxi/bookingcar/sequence/',{
                params: {
                    ing_status: 'F',
                    id: props.car.id
                }
          }).then(function(response) {
            response.data.map( (data_row) => {
                let s_date = new Date(data_row.s_date);
                let e_date = new Date(data_row.e_date);
                let a_date = new Date(data_row.s_date);
                let values = [0, 15, 30, 45, 60];
                let idx = 0;
                
                if ( dateFns.format(new Date(),"yyyy-MM-dd'T'00:00:00") === dateFns.format(s_date,"yyyy-MM-dd'T'00:00:00") ) {
                  while ((a_date <= e_date) && (idx < 5 )) {
                    a_date = dateFns.setMinutes(a_date, values[idx])
                  
                    if ((s_date <= a_date) && ( a_date <= e_date )) {
                      setExcludeTimes(excludeTimes => [...excludeTimes, a_date])
                    }
                    idx++
                  }
                }
            });
        }).catch(function (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(t('PROCESS_FAIL'));
          }
        });
        
        //exclude dates
        axiosInstance.get('/robotaxi/robocalendar/').then(
          function(response) {
            response.data.map( (data_row) => {
                setExcludeDates(excludeDates => [...excludeDates, new Date(data_row.applydate)]);
                if ( dateFns.format(new Date(),"yyyy-MM-dd'T'00:00:00") === dateFns.format(new Date(data_row.applydate),"yyyy-MM-dd'T'00:00:00") ) {
                  setIsHoliday(true);
                }
            });
        }).catch(function (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(t('PROCESS_FAIL'));
          }
        });
        
        //set min, maxtime
        let day = (new Date()).getDay();
        if (day !== 0 && day !== 6) {
          if (isHoliday) {
            setMinTime(new Date(0, 0, 0, 23, 59)); // 23:59am
            setMaxTime(new Date(0, 0, 0, 23, 59)); // 0:00pm
          } else {
            setMinTime(new Date(0, 0, 0, 10, 0)); // 10:00am
            setMaxTime(new Date(0, 0, 0, 16, 30)); // 4:30pm
          }
        } else {
          setMinTime(new Date(0, 0, 0, 23, 59)); // 23:59am
          setMaxTime(new Date(0, 0, 0, 23, 59)); // 0:00pm
        }
        
      } catch (e) {
        //onError(e);
      }
    }

    onLoad();
  }, []);  

 // 월/일 
 const getFormattedDate = (date) => { 
     const month = date.toLocaleDateString('ko-KR', { 
         month: 'long', 
     }); 
     const day = date.toLocaleDateString('ko-KR', { 
         day: 'numeric', 
     }); 
     return `${month.substr(0, month.length - 1)}/${day.substr(0, day.length - 1)}`; 
  } 
  // 요일 반환 
  const getDayName = (date) => { 
      return date.toLocaleDateString('ko-KR', { 
          weekday: 'long', 
      }).substr(0, 1); 
  } // 날짜 비교시 년 월 일까지만 비교하게끔 
  const createDate = (date) => { 
      return new Date(new Date(date.getFullYear() 
        , date.getMonth() 
        , date.getDate() 
        , 0 
        , 0 
        , 0)); 
  }
  
  const filterPassedDay = (time) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1)
    const selectedDate = new Date(time);

    return currentDate < selectedDate;
  };
  
  const filterPassedTime = (time) => {
    const minDate = new Date();
    const maxDate = new Date();
    const selectedDate = new Date(time);
    maxDate.setHours(17);

    return minDate.getTime() < selectedDate.getTime();
  };
  
  const isLunchTime = (date) => {
    const hour = date.getHours();
    return hour !== 12;
  };
  
  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };
  
  const setExcludeTime = (event) => {
    setExcludeTimes([]);
    let select_date = dateFns.format(event,"yyyy-MM-dd'T'00:00:00");
    setMinTime(new Date(0, 0, 0, 10, 0)); // 10:00am
    setMaxTime(new Date(0, 0, 0, 16, 30)); // 4:30pm
    // exclude times
    axiosInstance.get('/robotaxi/bookingcar/sequence/',{
      params: {
        ing_status: 'F',
        id: props.car.id
      }
    }).then(function(response) {
      response.data.map( (data_row) => {
        let s_date = new Date(data_row.s_date);
        let e_date = new Date(data_row.e_date);
        let a_date = new Date(data_row.s_date);
        let values = [0, 15, 30, 45, 60];
        let idx = 0;
                
        if ( dateFns.format(s_date,"yyyy-MM-dd'T'00:00:00") === select_date ) {
          while ((a_date <= e_date) && (idx < 5 )) {
            a_date = dateFns.setMinutes(a_date, values[idx])
                  
            if ((s_date <= a_date) && ( a_date <= e_date )) {
              setExcludeTimes(excludeTimes => [...excludeTimes, a_date])
            }
            idx++
          }
        }
      });
      
    }).catch(function (error) {
      if ( error.response.status === 444 ) {
        onError(error.response.data.message);
      } else {
        onError(t('PROCESS_FAIL'));
      }
    });
  }
  
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

 return (
   <Wrap>
    <DatePicker
        placeholderText={t('DATE001')}
        startDate={startDate}
        inputmode="none"
        showTimeSelect
        isClearable
        timeIntervals={5}
        dateFormat="yy.MM.dd(eee) HH:mm"
        locale={ko}
        selected={props.selected}
        onFocus={e => e.target.blur()}
        onChangeRaw={handleDateChangeRaw}
        onChange={props.onChange}
        onSelect={setExcludeTime}
        // 토요일, 일요일 색깔 바꾸기 위함 
        dayClassName={date => 
            getDayName(createDate(date)) === '토' ? "saturday" 
            : 
            getDayName(createDate(date)) === '일' ? "sunday" : undefined 
        }
    />
  </Wrap>
 );
}