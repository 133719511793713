import React, { useRef, useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import DropdownSelector from "../components/DropdownSelector";
import QRCode from "qrcode.react";
import { useTranslation } from 'react-i18next';

function RoboCarInfo() {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [car, setCar] = useState(null);
  const [carname, setCarname] = useState("");
  const [carstatus, setCarstatus] = useState("");
  const [route, setRoute] = useState("");
  const [station, setStation] = useState("");
  
  const [routeOptions, setRouteOptions] = useState([]);
  const [stationOptions, setStationOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([
    { name: "대기",id: "A"},
    { name: "배차",id: "B"},,
    { name: "수리",id: "C"},
  ]);
  
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  
  useEffect(() => {
    async function onLoad() {
      try {
        await axiosInstance.get(`/robotaxi/robocar/${id}/`).then(function(response) {
          console.log(response.data);
          const car = response.data;
          setCar(car);
          setCarname(car.carname);
          setCarstatus(car.car_status);
          setRoute(car.route);
          setStation(car.station);
        }).catch(function (error) {
          //console.log(error);
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(error);
          }
        });
        
        await axiosInstance.get('/robotaxi/route/').then(function(response) {
          //console.log(response.data);
          const routes = response.data;
          setRouteOptions(routes.map( data => ({ name: data.routename, id: data.id, route:data} ) ));
        }).catch(function (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(error);
          }
        });
        
        await axiosInstance.get('/robotaxi/station/').then(function(response) {
          //console.log(response.data);
          const stations = response.data;
          setStationOptions(stations.map( data => ({ name: data.stationname, id: data.id, station:data }) ));
        }).catch(function (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(error);
          }
        });
        
      } catch (e) {
        onError(e);
      }
    }
    
    onLoad();
  }, [id]);
  
  function validateForm() {
    return (
      carname.length > 0
    );
  }
  
  async function saveCar(car) {
    console.log(car);
    axiosInstance.put(`/robotaxi/robocar/${id}/`, car).then(function(response) {
      return;
    }).catch(function (error) {
      //console.log(error);
      if ( error.response.status === 444 ) {
        onError(error.response.data.message);
      } else {
        onError(error);
      }
      setIsLoading(false);
    }); 
  }
  
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await saveCar({
        id: car.id,
        carname: carname,
        car_status: carstatus,
        route: route,
        station: station
      });
    
      history.push("/robocar");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function deleteCar() {
    return axiosInstance.delete(`/robotaxi/robocar/${id}/`, car).then(function(response) {
      return;
    }).catch(function (error) {
      //console.log(error);
      if ( error.response.status === 444 ) {
        onError(error.response.data.message);
      } else {
        onError(error);
      }
      setIsLoading(false);
    }); 
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this note?"
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteCar();
      
      history.push("/robocar");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }
  
  function onChangeRoute(event) {
    const result = routeOptions.find(option => Number(option.id) === Number(event.target.value));
    setRoute(result.route);;
    //this.setState({ selectedValue: event.target.value });
  };
  
  function onChangeStation(event) {
    const result = stationOptions.find(option => Number(option.id) === Number(event.target.value));
    setStation(result.station);
    //this.setState({ selectedValue: event.target.value });
  };
  
  function onChangeState(event) {
    setCarstatus(event.target.value);
    //this.setState({ selectedValue: event.target.value });
  };
  
  const downloadQRCode = () => {
    const qrCodeURL = document.getElementById('qrCodeEl')
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(qrCodeURL)
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }
  
  return (
  <Stack gap={3}>
      <div className="bg-light border">
        
        <div className="Notes">
          {car && (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="carname">
                <Form.Label>차량명</Form.Label>
                <Form.Control
                  type="text"
                  value={carname}
                  onChange={(e) => setCarname(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="car_status">
                <DropdownSelector
                        labelName="상태"
                        options={stateOptions}
                        defaultValue={car.car_status}
                        onChange={onChangeState}
                />
              </Form.Group>
              <Form.Group controlId="route">
                <DropdownSelector
                        labelName="노선"
                        options={routeOptions}
                        defaultValue={car.route.id}
                        onChange={onChangeRoute}
                />
              </Form.Group>
              <Form.Group controlId="station">
                <DropdownSelector
                        labelName="정류장"
                        options={stationOptions}
                        defaultValue={car.station.id}
                        onChange={onChangeStation}
                />
              </Form.Group>
              <LoaderButton
                block
                size="lg"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Save
              </LoaderButton>
              <LoaderButton
                block
                size="lg"
                variant="danger"
                onClick={handleDelete}
                isLoading={isDeleting}
              >
                Delete
              </LoaderButton>
            </Form>
          )}
        </div>
        
      </div>
      <div className="bg-light border">
        <QRCode
            id="qrCodeEl"
            value={`{"id": "${id}", "carname": "${carname}"}`}
            size={256}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"L"}
            includeMargin={true}
        />
        <br/>
        <input 
          type="button" 
          className="download-btn" 
          value="Download" 
          onClick={downloadQRCode} 
        />
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default RoboCarInfo;