import React, { useState } from "react";

const html = `
<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
line-height:normal;mso-pagination:widow-orphan;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><b><span
style='font-size:9.0pt;font-family:굴림;mso-bidi-font-family:굴림;color:#666666;
mso-font-kerning:0pt'>이용약관</span></b><span lang=EN-US style='font-size:7.5pt;
font-family:굴림;mso-bidi-font-family:굴림;color:#666666;mso-font-kerning:0pt'><o:p></o:p></span></p>

`;

function TermsOfPersonal() {
    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    );
}

export default TermsOfPersonal;