import React, { useState, useEffect } from "react";
import { RenderAfterNavermapsLoaded, NaverMap, Marker, Polyline, Circle } from 'react-naver-maps'; // 패키지 불러오기
import { Overlay } from 'react-naver-maps';
import axiosInstance from "../axiosApi";
import { onError } from "../libs/errorLib";
import { useAppContext } from "../libs/contextLib";
import {usePosition} from '../usePosition';
import kakaoLogo from "../images/kakao_logo.png";

function NaverMapAPI(props) {
  const {latitude, longitude, error} = usePosition(true, {
    enableHighAccuracy: true,
    timeout: Infinity,
    maximumAge: 0,
  });
  const [station, setStation] = useState([]);
  const [paths, setPaths] = useState([]);
  const { routeAppContext } = useAppContext();
  const { strStationAppContext } = useAppContext();
  const navermaps = window.naver.maps;
  
  const icon = {
            url: 'http://static.naver.net/maps/v3/pin_default.png'
        };
        
  const icon_html = {
        content: '<i class="fas fa-bus-alt"></i>',
        size: new navermaps.Size(38, 58),
        anchor: new navermaps.Point(19, 58),
    };
    
  useEffect(() => {
        async function fetchStation() {
            try {
                axiosInstance.get('/robotaxi/routemap/',{
                    params: {id: routeAppContext.id}
                }).then(function(response) {
                    //console.log("NaverMapAPI onload event" + routeAppContext.routename);
                    //console.log(response.data);
                    let f_path = {};
                    f_path['lat'] = response.data[0].station.site_lat;
                    f_path['lng'] = response.data[0].station.site_lng;
                    
                    setStation(response.data);
                    
                    response.data.map( (data_row) => {
                      let path = {};
                
                      path['lat'] = data_row.station.site_lat
                      path['lng'] = data_row.station.site_lng
                      setPaths(paths => [...paths, path])
                    });
                    
                    setPaths(paths => [...paths, f_path])
                }).catch(function (error) {
                    //console.log(error);
                    if ( error.response.status === 444 ) {
                      onError(error.response.data.message);
                    }
                });

            } catch (e) {
                onError(e);
            }
        }
        fetchStation();
  }, []);
    
  
  return (
    <div>
      <NaverMap
        mapDivId={'maps-getting-started-uncontrolled'} // default: react-naver-map
        style={{
          width: '100%', // 네이버지도 가로 길이
          height: '40vh' // 네이버지도 세로 길이
        }}
        defaultCenter={{ lat: Number(props.ref_lat), lng: Number(props.ref_lng) }} // 지도 초기 위치
        defaultZoom={props.defalt_zoom} // 지도 초기 확대 배율
      >
        
        <Marker
            title="사용자의 현위치"
            key={0}
            position={new navermaps.LatLng(latitude, longitude)}
            animation={2}
            icon={icon}
        />
        
        {station.map((data, key) => {
            return (
            
                <Marker
                    title={data.station.stationname}
                    key={data.id}
                    position={new navermaps.LatLng(Number(data.station.site_lat), Number(data.station.site_lng))}
                    animation={2}
                />
            );
        })}
        
        <Polyline 
          path={paths}
          strokeColor={'#f04da8'}
          strokeOpacity={0.7}
          strokeWeight={3}
        />
        
      </NaverMap>
    </div>
  );
}

export default NaverMapAPI;