import React from "react";
import { Container, Nav, Navbar, Stack } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from 'react-i18next';
import "./SubNavigation.css";

function SubNavigationManager() {
  const { t } = useTranslation();
  
  return (

        <div className="bg-light border my-2">
          <Nav fill variant="pills">
              <LinkContainer to="/settings" className="nav-link">
                  <Nav.Link>{t('SETTINGS')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/routeline" className="nav-link">
                  <Nav.Link>{t('ROUTELINEMGT')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/station" className="nav-link">
                  <Nav.Link>{t('STATIONMGT')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/routemap" className="nav-link">
                  <Nav.Link>{t('ROUTEMAPMGT')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/robocar" className="nav-link">
                  <Nav.Link>{t('ROBOCARMGT')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/drivedata" className="nav-link">
                  <Nav.Link>차량주행정보</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/notes" className="nav-link">
                  <Nav.Link>공지사항관리</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/receiptmaster" className="nav-link">
                  <Nav.Link>영수증관리</Nav.Link>
              </LinkContainer>
              {/*
              <LinkContainer to="/qrscan" className="nav-link">
                  <Nav.Link>{t('QRSCAN')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/carsequence" className="nav-link">
                  <Nav.Link>{t('CARSEQUENCE')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/robocalendar" className="nav-link">
                  <Nav.Link>{t('ROBOCALENDAR')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/drivingrecord" className="nav-link">
                  <Nav.Link>운행기록조회</Nav.Link>
              </LinkContainer>
            */}
          </Nav>
        </div>

  );
}

export default SubNavigationManager;