import React, { useState, useEffect } from "react";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import ListGroup from "react-bootstrap/ListGroup";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../libs/contextLib";
import "./Notes.css";

export default function ReceiptMaster() {
  const [notes, setNotes] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

      if (!isAuthenticated) {
        return;
      }

      try {
        axiosInstance.get('/robotaxi/receiptmaster/').then(function(response) {
          setNotes(response.data);
        }).catch(function (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(error);
          }
          setIsLoading(false);
        }); 
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);

  }, [isAuthenticated]);

  return (
    <div className="notes">
      <LinkContainer to="/receiptmaster/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">Create a new Receipt</span>
          </ListGroup.Item>
        </LinkContainer>
        {notes.map(({ id, subject, receiptdate, payamount }) => (
          <LinkContainer key={id} to={`/receiptmaster/${id}`}>
            <ListGroup.Item action>
              <span className="font-weight-bold">
                {subject}
              </span>
              <br />
              <span className="font-weight-bold">
                {payamount}
              </span>
              <br />
              <span className="text-muted">
                결제일자: {new Date(receiptdate).toLocaleString()}
              </span>
            </ListGroup.Item>
          </LinkContainer>
        ))}
        <div>
            <br /><br /><br />
        </div>
    </div>
  );
}