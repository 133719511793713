import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import axiosInstance from "../axiosApi";
import { onError } from "../libs/errorLib";
import { FaCar } from "react-icons/fa";
import { IconContext } from "react-icons";
//import Marker from './Marker';

function GoogleMapAPI(props) {
  const [car1Name, setCar1Name] = useState("");
  const [car1SiteLat, setCar1SiteLat] = useState("");
  const [car1SiteLng, setCar1SiteLng] = useState("");
  const [car1Date, setCar1Date] = useState(null);
  const [places, setPlaces] = useState([]);

// InfoWindow component
const InfoWindow = (props) => {
  const { place } = props;
  const infoWindowStyle = {
    position: 'relative',
    bottom: 150,
    left: '-45px',
    width: 220,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 10,
    fontSize: 14,
    zIndex: 100,
  };

  return (
    <div style={infoWindowStyle}>
      <div style={{ fontSize: 16 }}>
        {place.name}
      </div>
      <div style={{ fontSize: 14 }}>
        <span style={{ color: 'grey' }}>
          {place.rating}
          {' '}
        </span>
        <span style={{ color: 'orange' }}>
          {String.fromCharCode(9733).repeat(Math.floor(place.rating))}
        </span>
        <span style={{ color: 'lightgrey' }}>
          {String.fromCharCode(9733).repeat(5 - Math.floor(place.rating))}
        </span>
      </div>
      <div style={{ fontSize: 14, color: 'grey' }}>
        {place.types[0]}
      </div>
      <div style={{ fontSize: 14, color: 'grey' }}>
        {'$'.repeat(place.price_level)}
      </div>
      <div style={{ fontSize: 14, color: 'green' }}>
        {place.opening_hours.open_now ? 'Open' : 'Closed'}
      </div>
    </div>
  );
};

// Marker component
const Marker = ({ show, place }) => {
  const markerStyle = {
    border: '1px solid white',
    borderRadius: '50%',
    height: 10,
    width: 10,
    backgroundColor: show ? 'red' : 'blue',
    cursor: 'pointer',
    zIndex: 10,
  };

  return (
    <>
      <div style={markerStyle} />
      {show && <InfoWindow place={place} />}
    </>
  );
};

  useEffect(() => {
    const timeout = setTimeout(() => getCar1(), 5000);
    return () => clearTimeout(timeout);
  }, [car1Date]);

  const MarkerCar1 = props => {
    return ( 
      <IconContext.Provider
          value={{style: { color: '#000000', fontSize: '30px' }}}
        >
          <div>
            <FaCar />
          </div>
      </IconContext.Provider>
    );
  }

  function getCar1() {
    try {
        axiosInstance.get('/robotaxi/robocar/2/').then(function(response) {
            setCar1Name(response.data.carname);
            setCar1SiteLat(response.data.site_lat);
            setCar1SiteLng(response.data.site_lng);
            setCar1Date(new Date());
        }).catch(function (error) {
            //console.log(error);
            if ( error.response.status === 444 ) {
              onError(error.response.data.message);
            }
        });
    } catch (e) {
        onError(e);
    }
  }

  const setMarkers = (map, maps) => {
    // Create an info window to share between markers.
    const infoWindow = new maps.InfoWindow(); 
    const geocoder = new maps.Geocoder;
    let latitude = null;
    let longitude = null;
 
    const image_kona =
    "https://www.lukesoft.kr:8081/robotaxi/inner/image/?filename=Icon-32.png";

    let marker_1 = new maps.Marker({
      position: {lat: props.latitude, lng: props.longitude},  
      map,
      title: '사용자 현위치',
      label: {
        fontFamily: 'Fontawesome',
        text: '\uf08d', //code for font-awesome icon
        fontSize: '15px',
        color: 'white'
      }
      //icon: {
      //    path: maps.SymbolPath.CIRCLE, //or any others
      //    scale: 8
      //}
      //icon: { url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png" }
    });
    
    marker_1.setMap(map);
    marker_1.addListener("click", () => {
      infoWindow.close();
      infoWindow.setContent(marker_1.getTitle());
      infoWindow.open(marker_1.getMap(), marker_1);
    }); 

    props.routeMap.map((data, key) => {
      let marker = new maps.Marker({
        position: {lat: Number(data.station.site_lat), lng: Number(data.station.site_lng)},  
        map,
        title: data.station.stationname,
        label: data.sequence
      });
      marker.setMap(map);
      // Add a click listener for each marker, and set up the info window.
      marker.addListener("click", () => {
        infoWindow.close();
        //infoWindow.setContent(marker.getTitle());
        infoWindow.setContent(getMarkInfoWindow(data));
        infoWindow.open(marker.getMap(), marker);
      }); 
    })
    
  }

  const getMarkInfoWindow = (data) => `
    <div>
      <div style="font-size: 12px;">
        ${data.station.stationname}
      </div>
      <div style="font-size: 12px; color: orange;">
        ${data.station.site_lat}
      </div>
      <div style="font-size: 12px; color: red;">
        ${data.station.site_lng}
      </div>
    </div>`;

  // Return map bounds based on list of places
  const getMapBounds = (map, maps) => {
    const bounds = new maps.LatLngBounds();

    props.routeMap.forEach((data) => {
      bounds.extend(new maps.LatLng(
        Number(data.station.site_lat),
        Number(data.station.site_lng),
      ));
    });
    return bounds;
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

  const handleApiLoaded = (map, maps) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
    setMarkers(map, maps);
    //setOverlayView(map, maps);
    //setKmlLayer(map, maps);
    //setDataLayer(map, maps);
    //setMarkersInfo(map, maps);
  };
  
  const setDataLayer = (map, maps) => {
    map.data.loadGeoJson(
      "https://www.lukesoft.kr:8081/robotaxi/inner/static/?filename=techno_cp.json"
    );
  }
  
  const setKmlLayer = (map, maps) => {
    //url: "https://www.lukesoft.kr:8081/robotaxi/inner/static/?filename=cta.kml",
    const kmlLayer = new maps.KmlLayer({
      url: "https://www.lukesoft.kr:8081/robotaxi/inner/static/?filename=c3_safty_v2.kml",
      map: map,
    });
  }
  
  const getMapOptions = (maps) => {
    return {
        streetViewControl: false,
        scaleControl: true,
        fullscreenControl: true,
        styles: [{
            featureType: "poi.business",
            elementType: "labels",
            stylers: [{
                visibility: "off"
            }]
        }],
        gestureHandling: "greedy",
        disableDoubleClickZoom: true,

        mapTypeControl: true,
        mapTypeId: maps.MapTypeId.ROADMAP,
        mapTypeControlOptions: {
            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: maps.ControlPosition.LEFT_TOP,
            mapTypeIds: [
                maps.MapTypeId.ROADMAP,
                maps.MapTypeId.SATELLITE,
                maps.MapTypeId.HYBRID,
                maps.MapTypeId.TERRAIN
            ]
        },

        zoomControl: true,
        clickableIcons: false
    };
  }

  // onChildClick callback can take two arguments: key and childProps
  const onChildClickCallback = (key) => {
    setPlaces((places) => {
      const index = places.findIndex((e) => e.id === key);
      places[index].show = !places[index].show; // eslint-disable-line no-param-reassign
      return places;
    });
  };

  return (
  <>
    <div style={{ width: '100%', height: '45vh', margin: 'auto'}}>
      <GoogleMapReact
        className="react-map"
        options={getMapOptions}
        bootstrapURLKeys={{
          key: "AIzaSyCjJQ1UO0BgWFnOgtvTinQ4PHzu8FoSVO0", 
          libraries: ['places', 'geometry', 'drawing', 'visualization'],
        }}
        defaultCenter={{ lat: Number(props.ref_lat), lng: Number(props.ref_lng) }} // 지도 초기 위치
        defaultZoom={props.defalt_zoom} // 지도 초기 확대 배율
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
            handleApiLoaded(map, maps);
            // access to visualization methods within `map.visualization`
          }
        }
      >

        <MarkerCar1 
          key="Car1"
          lat={car1SiteLat}
          lng={car1SiteLng}
          text={car1Name}
        />
        
      </GoogleMapReact>
    </div>
    <div>
      <br /><br /><br /><br /><br /><br />
    </div> 
  </>
  );
}

export default GoogleMapAPI;