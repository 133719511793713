import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import "./Home.css";
import axiosInstance from "../axiosApi";
import LayoutContext from '../components/LayoutContext';
import Navigation from '../components/Navigation';
import Section from '../components/Section';
import BusinessInfo from './BusinessInfo';
import CompanyInfo from './CompanyInfo';
import SolutionInfo from './SolutionInfo';
import CompanyContact from './CompanyContact';

export default function Home() {
  const [notes, setNotes] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isColumn, setIsColumn] = useState(true);
  const styleLayoutColumn = {
      flexDirection: isColumn ? 'column' : 'row',
  };
  
  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        axiosInstance.get('/notes/').then(function(response) {
          setNotes(response.data);
        }).catch(function (error) {
          console.log(localStorage.getItem('access_token'));
          onError(error);
          setIsLoading(false);
        }); 
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function loadNotes() {
    axiosInstance.get('/notes/').then(function(response) {
      return response.data;
    }).catch(function (error) {
      //console.log(error);
      onError(error);
      setIsLoading(false);
    }); 
  }
  
  function renderNotes() {
    return (
      <div className="notes">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Your Notes</h2>
        <ListGroup>{!isLoading && renderNotesList(notes)}</ListGroup>
      </div>
    );
  }
  
  function renderNotesList(notes) {
    return (
    <>
      <LinkContainer to="/notes/new">
        <ListGroup.Item action className="py-3 text-nowrap text-truncate">
          <BsPencilSquare size={17} />
          <span className="ml-2 font-weight-bold">Create a new note</span>
        </ListGroup.Item>
      </LinkContainer>
      {notes.map(({ id, content, createdat }) => (
        <LinkContainer key={id} to={`/notes/${id}`}>
          <ListGroup.Item action>
            <span className="font-weight-bold">
              {content.trim().split("\n")[0]}
            </span>
            <br />
            <span className="text-muted">
              Created: {new Date(createdat).toLocaleString()}
            </span>
          </ListGroup.Item>
        </LinkContainer>
      ))}
    </>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <LayoutContext.Provider value={[isColumn, setIsColumn]}>
            <div style={styleLayoutColumn}>
                <Navigation />
                <hr/>
                <Section id="box-1" color="white">
                    <CompanyInfo />
                </Section>
                <hr/>
                <Section id="box-2" color="blue">
                    <BusinessInfo />
                </Section>
                <hr/>
                <Section id="box-3" color="green">
                    <SolutionInfo />
                </Section>
                <hr/>
                <Section id="box-6" color="green">
                    <CompanyContact />
                </Section>
            </div>
        </LayoutContext.Provider>
      </div>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderNotes() : renderLander()}
    </div>
  );
}