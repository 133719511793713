import React, { useState } from "react";
import { Nav, Navbar, Stack, Form, Offcanvas } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import axios from 'axios'
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import kakaoLogo from "../images/kakao_logo.png";
import naverLogo from "../images/naver_logo.png";
import { KAKAO_AUTH_URL } from "../components/OAuth";
import { useTranslation } from 'react-i18next';
import config from "../config";

const FlexContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 48px);
	grid-column-gap: 8px;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
`;

const URLShareButton = styled.button`
	width: 48px;
	height: 48px;
	color: white;
	border-radius: 24px;
	border: 0px;
	font-weight: 800;
	font-size: 18px;
	cursor: pointer;
	background-color: #7362ff;
	&:hover {
		background-color: #a99fee;
	}
`;

const NaverShareButton = styled.a`
    cursor: pointer;
    border-radius: 24px;
	border: 0px;
    &:hover {
		background-color: #4177c6;
	}
  `;
  
const KakaoShareButton = styled.a`
    cursor: pointer;
    border-radius: 24px;
	border: 0px;
    &:hover {
		background-color: #4177c6;
	}
  `;

const KakaoIcon = styled.img`
	width: 48px;
	height: 48px;
	border-radius: 24px;
  `;

function GetEmail({ name, ...props }) {
  const [show, setShow] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const { t } = useTranslation();

  const handleClose = () => {
      setShow(false);
      props.onChangeEmail(email);
  }
  
  const handleShow = () => {
      setShow(true);
  }
  
  function validateForm() {
    return phone.length > 0 ;
  }

  function handleSubmitFind(event) {
    event.preventDefault();

    axios.get(`${config.DJANGO_URL}/accounts/get_snsuser_by_phone/`, {
              params: {  
                  phone_number: phone
              }
    }).then(function(response) {
          setEmail(response.data.email);
    }).catch(function (error) {
        onError("휴대폰번호와 일치하는 비밀번호가 없습니다.");
    });
  }
  
  return (
    <>
      <button type="button" class="LoaderButton  btn btn-dark btn-lg" onClick={handleShow} >{name}</button>
      
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="text-white bg-dark">{t('FINDEMAIL')}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className=" border">
          <Form>
            <Form.Group size="lg" controlId="phone">
              <Form.Label>{t('F_EMAIL_LABEL')}</Form.Label>
                <Form.Control
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
            </Form.Group>
            <Form.Group size="lg" controlId="email">
              <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={email}
                  onChange={props.onChangeEmail}
                />
            </Form.Group>
            <LoaderButton
              block
              size="lg"
              variant="dark"
              onClick={handleSubmitFind}
              disabled={!validateForm()}
            >
              Email찾기
            </LoaderButton>
          </Form>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default function LoginSocial() {
  const { t } = useTranslation();
  const { user, setUser } = useAppContext();
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [message, setMessage] = useState(t('AUTHMSG'));
  const [isConfirm, setIsConfirm] = useState(true);

  async function handleSocialSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    
    if (!isConfirm) {
      axios.post(`${config.DJANGO_URL}/accounts/checkemail/`, {
                authcode: email,
      }).then(function(response) {
        if (response.data.access === 'ok') {
          setIsConfirm(true);
        } else {
          setMessage(t('AUTHFAILMSG2'));
        }
        setIsLoading(false);
      }).catch(function (error) {
        if (typeof error === 'object') {
          //console.log(error.response);
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          }
        }
        onError(t('AUTHFAILMSG2'));
        userHasAuthenticated(false);
        setIsLoading(false);
      });
    }
  }
  
  return (
  <Stack gap={3}>
    <div className="bg-light border">
        <Nav sticky="top">
              <LinkContainer to="/loginsocial">
                  <Nav.Link>SNS {t('LOGIN')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/loginbasic">
                  <Nav.Link>E-mail {t('LOGIN')}</Nav.Link>
              </LinkContainer>
        </Nav>
    </div>
    {/*
    <div className="bg-light border">
      <h6>{t('LOGIN001')}</h6>
      <Form onSubmit={handleSocialSubmit}>
        <Form.Group size="lg" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
        </Form.Group>
        <LoaderButton
            block
            size="lg"
            type="submit"
            variant="dark"
            isLoading={isLoading}
            disabled={isConfirm}
        >
            {t('AUTHVERIFY')}
        </LoaderButton>
        <GetEmail key={1} placement='bottom' 
                            name={t('FINDEMAIL')}
                            onChangeEmail={setEmail} 
                             />
      </Form>
    </div>
    */}
    <div>
      {isConfirm ? (
        <FlexContainer>
			        <GridContainer>
				        <URLShareButton ></URLShareButton>
				        <KakaoShareButton href={KAKAO_AUTH_URL} >
		              <KakaoIcon src={kakaoLogo}></KakaoIcon>
		            </KakaoShareButton>
				        <URLShareButton ></URLShareButton>
				    </GridContainer>
				</FlexContainer>
			) : (
			  <>
			  <h5>{message}</h5>
			  </>
			)}
    </div>
    <div>
        <br /><br /><br />
    </div>
  </Stack>
  );
}