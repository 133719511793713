import React, { useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { BsPencilSquare } from "react-icons/bs";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import { useTranslation } from 'react-i18next';

function RouteLIne() {
  const { t } = useTranslation();
  const [routes, setRoutes] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
      
      try {
        axiosInstance.get('/robotaxi/route/').then(function(response) { 
          const routes = response.data;
          setRoutes(routes);
        }).catch(function (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(error);
          }
        });
      } catch (e) {
        onError(e);
      }
      
      setIsLoading(false);
    }
    
    onLoad();
  }, [isAuthenticated]);
  
  function renderRoutesList(routes) {
    return (
      <>
        <LinkContainer to="/routeline/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">Create a New Route</span>
          </ListGroup.Item>
        </LinkContainer>
        {routes.map(({ id, routename, ref_lat, ref_lng, defalt_zoom, crt_date }) => (
          <LinkContainer key={id} to={`/routeline/${id}`}>
            <ListGroup.Item action>
              <span className="font-weight-bold">
                {routename}
              </span>
              <br />
              <span className="font-weight-bold">
                {ref_lat}
              </span>
              <br />
              <span className="font-weight-bold">
                {ref_lng}
              </span>
              <br />
              <span className="font-weight-bold">
                {defalt_zoom}
              </span>
              <br />
              <span className="text-muted">
                Created: {new Date(crt_date).toLocaleString()}
              </span>
            </ListGroup.Item>
          </LinkContainer>
        ))}
      </>
    );
  }
  
  return (
  <Stack gap={3}>
      <div className="bg-light border titlesubject">
        {t('ROUTELINEMGT')}
      </div>
      <div className="routes">
        <ListGroup>{!isLoading && renderRoutesList(routes)}</ListGroup>
      </div>
        
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default RouteLIne;