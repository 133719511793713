import React, { useState } from "react";

const html = `
<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>개인정보</span></b><b><span
style='font-size:9.0pt;font-family:"Nanum_Barun_regular",serif;color:#666666'> </span></b><b><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>처리방침</span></b></p>

`;

function TermsOfPrivacy() {
    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    );
}

export default TermsOfPrivacy;