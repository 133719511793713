import React, { useRef, useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import { useFormFields } from "../libs/hooksLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import { useTranslation } from 'react-i18next';

function RouteLIneNew() {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    routename: "",
    ref_lat: "",
    ref_lng: "",
    defalt_zoom: "12",
  });
  
  function validateForm() {
    return (
      fields.routename.length > 0 &&
      fields.ref_lat.length > 0 &&
      fields.ref_lng.length > 0
    );
  }
  
  function createRoute(route) {
    axiosInstance.post(`/robotaxi/route/`, route).then(function(response) {
      return;
    }).catch(function (error) {
      //console.log(error);
      if ( error.response.status === 444 ) {
        onError(error.response.data.message);
      } else {
        onError(error);
      }
      setIsLoading(false);
    }); 
  }
  
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await createRoute({
        routename: fields.routename,
        ref_lat: fields.ref_lat,
        ref_lng: fields.ref_lng,
        defalt_zoom: fields.defalt_zoom,
      });
    
      history.push("/routeline");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  return (
  <Stack gap={3}>
      <div className="bg-light border">
        
        <div className="Notes">
          <h3 className="pb-3 mt-4 mb-3 border-bottom">{t('INPUT_SCREEN')}</h3>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="routename">
                <Form.Label>노선명</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.routename}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="ref_lat">
                <Form.Label>위도</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.ref_lat}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="ref_lng">
                <Form.Label>경도</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.ref_lng}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="defalt_zoom">
                <Form.Label>지도Zoom값</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.defalt_zoom}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <LoaderButton
                block
                size="lg"
                type="submit"
                variant="primary"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Create
              </LoaderButton>
            </Form>
        </div>
        
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default RouteLIneNew;