import React, { useRef, useState, useEffect } from "react";
import {Form, Row, Col, ButtonGroup, Offcanvas, Button, Container, Modal} from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { Nav } from 'react-bootstrap';
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
import TableDateTimePicker from "./TableDateTimePicker";
import { useAppContext } from "../libs/contextLib";
import { FaHandPointRight } from "react-icons/fa";
import axiosInstance from "../axiosApi";
import moment from "moment";
import 'moment/locale/ko';
import { useTranslation } from 'react-i18next';
import DropdownSelector from "../components/DropdownSelector";
import config from "../config";
import * as dateFns from "date-fns";

function RoundTicketModal(props) {
  const { t } = useTranslation();
  const { setStrRoundDateAppContext } = useAppContext();
  const { setCarRoundAppContext } = useAppContext();
  const { carRoundAppContext } = useAppContext();

  function onChangeCar(event) {
    const result = props.carOptions.find(option => Number(option.id) === Number(event.target.value));
    if (result) {
      setCarRoundAppContext(result.car);
    } else {
      setCarRoundAppContext("");
      setStrRoundDateAppContext("")
    }
  }

  function onChangeStrRoundDate(event) {
    // 편도출발시간 2시간이후 부터 예약가능시간 적용
    let minutes = 0;

    minutes = dateFns.differenceInMinutes(new Date(props.strSingleDateAppContext), new Date(event))
    if ( (Math.abs(minutes) > 120) && dateFns.isAfter(new Date(event), new Date(props.strSingleDateAppContext)) ) {
      let select_date = dateFns.format(event,"HH");
      if (select_date === '00') {
        let minDate = new Date(event);
        minDate.setHours(10);
        setStrRoundDateAppContext(minDate);
      } else {
        setStrRoundDateAppContext(event);
      }
    } else {
      alert(t('MSG_0003'));
      let select_date = dateFns.format(dateFns.addHours(new Date(props.strSingleDateAppContext),2),"HH");
      if (select_date === '12') {
        setStrRoundDateAppContext(dateFns.addHours(new Date(props.strSingleDateAppContext),3));
      } else {
        if (select_date >= '14') {
          setStrRoundDateAppContext(dateFns.addDays(new Date(props.strSingleDateAppContext),1));
        } else {
          setStrRoundDateAppContext(dateFns.addHours(new Date(props.strSingleDateAppContext),2));
        }
      }
    }
  }


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Container>
          <Row>
            <Modal.Title id="contained-modal-title-vcenter" className="text-white bg-dark">{t('ROUNDTICKET')}</Modal.Title>
          </Row>
          <Row>
            <h6>{t('ROUNDTICKETINFO')}</h6>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <div className=" border">
          <Form className="form">
            <Row className="mx-1">
                <Col sm={6} >       
                    <Form.Group as={Row} controlId="formHorizontal">
                        <Col className="my-1" xs={4} md={4}>
                            <Form.Label>{t('CAR')}</Form.Label>
                        </Col>
                        <Col className="my-1" xs={8} md={8}>
                            <DropdownSelector
                                labelName=""
                                options={props.carOptions}
                                defaultValue={carRoundAppContext.id}
                                onChange={onChangeCar}
                            />
                        </Col>
                    </Form.Group>
                </Col>
                <Col sm={6} >
                    <Form.Group as={Row} controlId="formHorizontal">
                        <Col className="my-1" xs={4} md={4}>
                            <Form.Label>{t('DEPARTTIME')}</Form.Label>
                        </Col>
                        <Col className="my-1" xs={8} md={8}>    
                            { (carRoundAppContext.length != 0) && (
                                <TableDateTimePicker
                                    wrapperClassName="datepicker"
                                    selected={props.strRoundDateAppContext}
                                    onChange={onChangeStrRoundDate} 
                                    car={carRoundAppContext}
                                />
                            )}
                        </Col>
                    </Form.Group>
                </Col>  
            </Row>
            <Row className="mx-1">
                <Col sm={6} >
                    <Form.Group as={Row} controlId="formHorizontal">
                        <Col className="my-1" xs={4} md={4}>
                            <Form.Label className="text-center">{t('DEPART')}</Form.Label>
                        </Col>
                        <Col className="my-1" xs={8} md={8}>
                            <Form.Control 
                                className="mx-1"
                                type="text" 
                                size="sm"
                                readOnly
                                value={props.endStationAppContext === '' ? '' : props.endStationAppContext.station.stationname}
                            />
                        </Col>
                    </Form.Group>
                </Col>
                <Col sm={6} >
                    <Form.Group as={Row} controlId="formHorizontal">
                        <Col className="my-1" xs={4} md={4}>
                            <Form.Label>{t('ARRIVAL')}</Form.Label>
                        </Col>
                        <Col className="my-1" xs={8} md={8}>
                            <Form.Control 
                                type="text" 
                                size="sm"
                                readOnly
                                value={props.strStationAppContext === '' ? '' : props.strStationAppContext.station.stationname}
                            />
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
          </Form>
          </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function Destination() {
  const [ticketGubun, setTicketGubun] = useState("S");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const history = useHistory();
  const [modalShow, setModalShow] = React.useState(false);
  const [carOptions, setCarOptions] = useState([]);
  
  const { setRouteAppContext } = useAppContext();
  const { routeAppContext } = useAppContext();
  
  const { ticketGubunAppContext } = useAppContext();
  const { setTicketGubunAppContext } = useAppContext();
  
  const { strRoundDateAppContext } = useAppContext();
  const { setStrRoundDateAppContext } = useAppContext();
  
  const { endRoundDateAppContext } = useAppContext();
  const { setEndRoundDateAppContext } = useAppContext();
  
  const { strStationAppContext } = useAppContext();
  const { setStrStationAppContext } = useAppContext();
    
  const { endStationAppContext } = useAppContext();
  const { setEndStationAppContext } = useAppContext();
  
  const { endSingleDateAppContext } = useAppContext();
  const { setEndSingleDateAppContext } = useAppContext();
    
  const { chargeAppContext } = useAppContext();
  const { setChargeAppContext } = useAppContext();
    
  const { setCarAppContext } = useAppContext();
  const { carAppContext } = useAppContext();
  
  const { setCarRoundAppContext } = useAppContext();
  const { carRoundAppContext } = useAppContext();

  const { setStrSingleDateAppContext } = useAppContext();
  const { strSingleDateAppContext } = useAppContext();
  
  const { t } = useTranslation();
  
  const onChangeHandlerSingle = (arg_ticket, event) => {
      setTicketGubunAppContext('S');
      setStrRoundDateAppContext('');
      setCarRoundAppContext('');
  }

  const onChangeHandlerRound = (arg_ticket, event) => {
    setTicketGubunAppContext('R');
    showModal();
  }

  var singlelt = 0, roundlt = 0, singlerd = 0, roundrd = 0;
  
  useEffect(() => {
    async function onLoad() {
      try {      
        await axiosInstance.get('/robotaxi/robocar/').then(function(response) {
          //console.log(response.data[0]);
          const cars = response.data;
          setCarAppContext(response.data[0]);
          setCarOptions(cars.map( data => ( { name: data.carname, id: data.id, car:data} ) ));
        }).catch(function (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(error);
          }
        });
         
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, []);

  function validateForm() {
    return String(strSingleDateAppContext).length > 0 && 
      (Object.keys(strStationAppContext).length != 0) && 
      (Object.keys(endStationAppContext).length != 0);
  }
  
  async function getChargeAndTime() {
        try {
            const response = await axiosInstance.get('/robotaxi/routemap/resultcircle/',{
                params: {
                    id: routeAppContext.id,
                    s_book: strStationAppContext.station.id,
                    e_book: endStationAppContext.station.id
                }
            });
            let strtime, endtime;
            strtime = new Date(strSingleDateAppContext);
            endtime = moment(strtime).add(Number(response.data.sum_leadtime), 'minutes')
            setEndSingleDateAppContext(endtime);
            setChargeAppContext(String(3000));
            singlelt = Number(response.data.sum_leadtime);
            singlerd = Number(response.data.sum_rundistance);
        } catch (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(error);
          }
        }
    }

    async function getRoundLeadTime() {
      try {
          const response = await axiosInstance.get('/robotaxi/routemap/resultcircle/',{
              params: {
                  id: routeAppContext.id,
                  s_book: endStationAppContext.station.id,
                  e_book: strStationAppContext.station.id
              }
          });
          let strtime, endtime;
          strtime = new Date(strRoundDateAppContext);
          endtime = moment(strtime).add(Number(response.data.sum_leadtime), 'minutes')
          setEndRoundDateAppContext(endtime);
          roundlt = Number(response.data.sum_leadtime);
          roundrd = Number(response.data.sum_rundistance);
      } catch (error) {
        if ( error.response.status === 444 ) {
          onError(error.response.data.message);
        } else {
          onError(error);
        }
      }
  }
  
  async function handleSubmit(event) {
    event.preventDefault();

    if (strRoundDateAppContext === '' || strRoundDateAppContext === null) {
      setTicketGubunAppContext('S');
      await getChargeAndTime();
    } else {
      setTicketGubunAppContext('R');
      await getChargeAndTime();
      await getRoundLeadTime();
    }

    history.push({
      pathname: '/billroute',
      state: {
        singleLeadTime: singlelt,
        roundLeadTime: roundlt,
        singleRunDistance: singlerd,
        roundRunDistance: roundrd,
      }
    });
    setIsLoading(false);

  }
 
  function onChangeCar(event) {
    const result = carOptions.find(option => Number(option.id) === Number(event.target.value));
    if (result) {
      setCarAppContext(result.car);
    } else {
      setCarAppContext("");
      setStrSingleDateAppContext("");
    }
  }

  function onChangeStrSingleDate(event) {
    // 현재시간 1시간이후 부터 예약가능시간 적용
    let minutes = 0;

    minutes = dateFns.differenceInMinutes(new Date(), new Date(event))
    if ( (Math.abs(minutes) > 60) && dateFns.isAfter(new Date(event), new Date()) ) {
      let select_date = dateFns.format(event,"HH");
      if (select_date === '00') {
        let minDate = new Date(event);
        minDate.setHours(10);
        setStrSingleDateAppContext(minDate);
      } else {
        setStrSingleDateAppContext(event);
      }
    } else {
      alert(t('MSG_0002'));
      setStrSingleDateAppContext('');
    }
    setStrRoundDateAppContext('');
  }

  function showModal() {
    if (carRoundAppContext === '' || carRoundAppContext === null) {
      setCarRoundAppContext(carAppContext);
    }
    setModalShow(true);
  }
  
  function hideModal() {
    if (strRoundDateAppContext === '' || strRoundDateAppContext === null) {
      setTicketGubunAppContext('S');
    } else {
      setTicketGubunAppContext('R');
    }
    setModalShow(false);
  }

  return (
  <>
    <div className="Destination bg-white">
      <Form className="form2" onSubmit={handleSubmit}>
        <Row className="mx-1 my-1">
          <Col sm={6} >       
            <Form.Group as={Row} controlId="formHorizontal">
                <Col className="my-1" xs={4} md={4}>
                    <Form.Label>{t('CAR')}</Form.Label>
                </Col>
                <Col className="my-1" xs={8} md={8}>
                    <DropdownSelector
                        labelName=""
                        options={carOptions}
                        defaultValue={carAppContext.id}
                        onChange={onChangeCar}
                    />
                </Col>
            </Form.Group>
          </Col>
          <Col sm={6} >
            <Form.Group as={Row} controlId="formHorizontal">
                <Col className="my-1" xs={4} md={4}>
                    <Form.Label>{t('DEPARTTIME')}</Form.Label>
                </Col>
                <Col className="my-1" xs={8} md={8}>
                    { (carAppContext.length != 0) && (
                          <TableDateTimePicker
                            wrapperClassName="datepicker"
                            selected={strSingleDateAppContext}
                            onChange={onChangeStrSingleDate} 
                            car={carAppContext}
                          />
                    )}
                </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mx-1 my-1">
          <Col sm={6} >
            <Form.Group as={Row} controlId="formHorizontal">
                <Col className="my-1" xs={4} md={4}>
                    <Form.Label>{t('JOURNEY')}</Form.Label>
                </Col>
                <Col className="my-1" xs={8} md={8}>
                    <ButtonGroup className="me-2" aria-label="First group">
                        <Button variant={ticketGubunAppContext === 'S' ? 'dark' : 'outline-dark'} value={ticketGubunAppContext} onClick={(e) => onChangeHandlerSingle(ticketGubunAppContext,e)}>{t('ONEWAY')}</Button>
                    </ButtonGroup>
                    <ButtonGroup className="me-2" aria-label="First group">
                        <Button variant={ticketGubunAppContext === 'R' ? 'dark' : 'outline-dark'} value={ticketGubunAppContext} onClick={(e) => onChangeHandlerRound(ticketGubunAppContext,e)}>{t('ROUNDTRIP')}</Button>
                    </ButtonGroup>
                </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mx-1 my-1">
            <LoaderButton
                block
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
            >
                {t('VERIFYBOOKING')}
            </LoaderButton>
        </Row>
      </Form>
    </div>
    <div>
      <RoundTicketModal
        show={modalShow}
        onHide={hideModal}
        strStationAppContext={strStationAppContext}
        strSingleDateAppContext={strSingleDateAppContext}
        endStationAppContext={endStationAppContext}
        endRoundDateAppContext={endRoundDateAppContext}
        strRoundDateAppContext={strRoundDateAppContext}
        ticketGubunAppContext = {ticketGubunAppContext}
        chargeAppContext={chargeAppContext}
        car={carAppContext}
        carOptions={carOptions} />
    </div>
  </>
  );
}