import { forwardRef } from 'react';
import styles from './FancyButton.module.css';
import { Nav, Navbar, NavItem, NavDropdown } from "react-bootstrap";

const FancyButton = forwardRef(({ href, text, onClick }, ref) => (
  ({href} === "#box-1") ?
    <Navbar.Brand ref={ref} href={href} onClick={onClick}>
      {text}
    </Navbar.Brand>
  :
  <Nav.Link ref={ref} href={href} onClick={onClick}>
    <b>{text}</b>
  </Nav.Link>
 ));

export default FancyButton;
