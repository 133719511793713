import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Stack } from 'react-bootstrap';
import { useParams, useHistory, Link } from "react-router-dom";
import axiosInstance from "../axiosApi";
import { onError } from "../libs/errorLib";
import moment from "moment";
import 'moment/locale/ko';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useTranslation } from 'react-i18next';
import ToggleSwitch from "../components/ToggleSwitch";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { IconContext } from "react-icons";

function BookingCar() {
  const [bookingCar, setBookingCar] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();
  const [stateOptions, setStateOptions] = useState([
    { name: t('INGSTATUS_001'),id: "A"},
    { name: t('INGSTATUS_002'),id: "B"},,
    { name: t('INGSTATUS_003'),id: "C"},
    { name: t('INGSTATUS_004'),id: "D"},
    { name: t('INGSTATUS_005'),id: "E"},
    { name: t('INGSTATUS_006'),id: "F"},
    { name: t('INGSTATUS_007'),id: "G"},
    { name: t('INGSTATUS_008'),id: "H"},
  ]);
  
  const columns = [
    {
        dataField: "s_date",
        text: t('COL_TIME'),
        sort: true
    },
    {
        dataField: "s_station",
        text: t('COL_DEPART')
    },
    {
        dataField: "e_station",
        text: t('COL_ARRIVAL')
    },
    {
        dataField: "ing_status",
        text: t('COL_STATUS')
    },
    {
        dataField: "car",
        text: t('COL_CAR')
    }
  ];
  
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <IconContext.Provider
          value={{style: { color: '#A4BEF3', fontSize: '30px' }}}
        >
          <div>
            <FaCloudDownloadAlt onClick={ handleClick } />
            {" "}To CSV
          </div>
      </IconContext.Provider>
        //<button className="btn btn-success" onClick={ handleClick }>다운로드 CSV</button>
    );
  };

  const products = [
    { id: 1, s_station: "디지스트 R4", s_date: "2021-11-30T11:15:00", e_station: "유가우체국", ing_status: "배차진행" },
    { id: 2, s_station: "디지스트 R4", s_date: "2021-11-30T11:15:00", e_station: "유가우체국", ing_status: "차량이동" },
    { id: 3, s_station: "디지스트 R4", s_date: "2021-11-30T11:15:00", e_station: "유가우체국", ing_status: "승차대기" }
  ];

  const [isSwitchOnCheckCancel, setIsSwitchOnCheckCancel] = useState(true);

  const onSwitchActionCheckCancel = () => {
    if (!isSwitchOnCheckCancel) {
      try {
        axiosInstance.get('/robotaxi/bookingcar/', {
          params:{ ing_status: 'F',}
        }).then(function(response) {
          setBookingCar([]);
          response.data.map( (data_row) => {
            let book = {};
            
            book['id'] = data_row.id
            book['s_station'] = data_row.s_station.stationname
            book['s_date'] = moment(new Date(data_row.s_date)).format("MM.DD(ddd) HH:mm")
            book['e_station'] = data_row.e_station.stationname
            book['ing_status'] = data_row.ing_status_name
            book['car'] = data_row.car.carname
            setBookingCar(bookingCar => [...bookingCar, book])
            //setBookingCar(bookingCar.concat(book))
          });
        }).catch(function(error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(error);
          }
        });
      } catch (e) {
        onError(e);
      }
    } else {
      try {
        axiosInstance.get('/robotaxi/bookingcar/', {
          params:{ ing_status: 'G',}
        }).then(function(response) {
          setBookingCar([]);
          response.data.map( (data_row) => {
            let book = {};
            
            book['id'] = data_row.id
            book['s_station'] = data_row.s_station.stationname
            book['s_date'] = moment(new Date(data_row.s_date)).format("MM.DD(ddd) HH:mm")
            book['e_station'] = data_row.e_station.stationname
            book['ing_status'] = data_row.ing_status_name
            book['car'] = data_row.car.carname
            setBookingCar(bookingCar => [...bookingCar, book])
            //setBookingCar(bookingCar.concat(book))
          });
        }).catch(function(error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(error);
          }
        });
      } catch (e) {
        onError(e);
      }
    }
    setIsSwitchOnCheckCancel(!isSwitchOnCheckCancel);
  };
  
  useEffect(() => {
    async function onLoad() {
      try {
        axiosInstance.get('/robotaxi/bookingcar/', {
          params:{ ing_status: 'F',}
        }).then(function(response) {
            
            response.data.map( (data_row) => {
                let book = {};
                
                book['id'] = data_row.id
                book['s_station'] = data_row.s_station.stationname
                book['s_date'] = moment(new Date(data_row.s_date)).format("MM.DD(ddd) HH:mm")
                book['e_station'] = data_row.e_station.stationname
                book['ing_status'] = data_row.ing_status_name
                book['car'] = data_row.car.carname
                setBookingCar(bookingCar => [...bookingCar, book])
                //setBookingCar(bookingCar.concat(book))
            });
        }).catch(function (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(error);
          }
        }); 
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, []);
  
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
        console.log(`clicked on row with index: ${rowIndex}`);
        history.push(`/boarding/${row['id']}`);
    }
  };
  
  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    unselectable: [2],
    selected: [1],
    onSelect: onSelectRow,
     bgColor: 'skyblue'
  };
  
  function onSelectRow(row, isSelected, e) {
    if (isSelected) {
        console.log(`You just selected '${row['s_date']}'`);
    }
  };
  
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group" role="group">
      {
        options.map((option) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={ option.text }
              type="button"
              onClick={ () => onSizePerPageChange(option.page) }
              className={ `btn ${isSelect ? 'btn-dark' : 'btn-secondary'}` }
            >
              { option.text }
            </button>
          );
        })
      }
    </div>
  );

  const options = {
    sizePerPageRenderer
  };

  return (
  <Stack gap={3}>
      {t('PROGRESS_INFO')}
      <div className="bg-light border">
        <ToolkitProvider
          keyField="s_date"
          data={ bookingCar }
          columns={ columns }
          exportCSV
        >
          {
            props => (
              <div>
                <Container>
                  <Row>
                    <Col>
                      <ToggleSwitch label={t('EXCEPT_CANCEL')} isChecked={isSwitchOnCheckCancel} onChange={onSwitchActionCheckCancel}/>
                    </Col> 
                  </Row>
                </Container>
                <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={bookingCar}
                    
                    columns={columns}
                    striped
                    hover
                    condensed
                    bordered={ false }
                    rowEvents={ rowEvents }
                    pagination={paginationFactory(options)}
                />
              </div>
            )
          }
        </ToolkitProvider>
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default BookingCar;