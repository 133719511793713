import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import LoginSocial from "./containers/LoginSocial";
import LoginBasic from "./containers/LoginBasic";
import SignupSocial from "./containers/SignupSocial";
import SignupBasic from "./containers/SignupBasic";
import SignupPhone from "./containers/SignupPhone";
import BookingStation_NaverMap from "./containers/BookingStation_NaverMap";
import BookingStation_GoogleMaps from "./containers/BookingStation_GoogleMaps";
import BillRoute from "./containers/BillRoute";
import BookingCar from "./containers/BookingCar";
import Settings from "./containers/Settings";
import RouteLIne from "./containers/RouteLIne";
import RouteLineNew from "./containers/RouteLineNew";
import RouteLineInfo from "./containers/RouteLineInfo";
import Station from "./containers/Station";
import StationNew from "./containers/StationNew";
import StationInfo from "./containers/StationInfo";
import RoboCar from "./containers/RoboCar";
import RoboCarNew from "./containers/RoboCarNew";
import RoboCarInfo from "./containers/RoboCarInfo";
import RouteMap from "./containers/RouteMap";
import RouteMapNew from "./containers/RouteMapNew";
import RouteMapInfo from "./containers/RouteMapInfo";
import RoboCalendar from "./containers/RoboCalendar";
import RoboCalendarNew from "./containers/RoboCalendarNew";
import RoboCalendarInfo from "./containers/RoboCalendarInfo";
import QrScan from "./containers/QrScan";
import BoardingTicket from "./containers/BoardingTicket";
import BoardingQrScan from "./containers/BoardingQrScan";
import CarSequenceList from "./containers/CarSequenceList";
import UserDetail from "./containers/UserDetail";
import PasswordResetView from "./containers/PasswordResetView";
import SignupConfirm from "./containers/SignupConfirm";
import OAuth2RedirectHandler from "./components/OAuth2RedirectHandler";
import Jtnet2RedirectHandler from "./components/Jtnet2RedirectHandler";
import JtnetGeneral2RedirectHandler from "./components/JtnetGeneral2RedirectHandler";
import NotFound from "./containers/NotFound";
import NoteNew from "./containers/NoteNew_ckeditor";
import NoteInfo from "./containers/NoteInfo_ckeditor";
import Notes from "./containers/Notes_ckeditor";
import DrivingRecord from "./containers/DrivingRecord";
import ReceiptMaster from "./containers/ReceiptMaster";
import ReceiptMasterNew from "./containers/ReceiptMasterNew";
import ReceiptMasterInfo from "./containers/ReceiptMasterInfo";

export default function Routes() {
  
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/loginsocial">
        <LoginSocial />
      </Route>
      <Route exact path="/loginbasic">
        <LoginBasic />
      </Route>
      <Route exact path="/signupsocial">
        <SignupSocial />
      </Route>
      <Route exact path="/signupbasic">
        <SignupBasic />
      </Route>
      <Route exact path="/signupphone">
        <SignupPhone />
      </Route>
      <Route exact path="/bookingstation">
        <BookingStation_GoogleMaps />
      </Route>
      <Route exact path="/billroute">
        <BillRoute />
      </Route>
      <Route exact path="/bookingcar">
        <BookingCar />
      </Route>
      <Route exact path="/settings">
        <Settings />
      </Route>
      <Route exact path="/routeline">
        <RouteLIne />
      </Route>
      <Route exact path="/routeline/new">
        <RouteLineNew />
      </Route>
      <Route exact path="/routeline/:id">
        <RouteLineInfo />
      </Route>
      <Route exact path="/station">
        <Station />
      </Route>
      <Route exact path="/station/new">
        <StationNew />
      </Route>
      <Route exact path="/station/:id">
        <StationInfo />
      </Route>
      <Route exact path="/robocar">
        <RoboCar />
      </Route>
      <Route exact path="/robocar/new">
        <RoboCarNew />
      </Route>
      <Route exact path="/robocar/:id">
        <RoboCarInfo />
      </Route>
      <Route exact path="/routemap">
        <RouteMap />
      </Route>
      <Route exact path="/routemap/new">
        <RouteMapNew />
      </Route>
      <Route exact path="/routemap/:id">
        <RouteMapInfo />
      </Route>
      <Route exact path="/robocalendar">
        <RoboCalendar />
      </Route>
      <Route exact path="/robocalendar/new">
        <RoboCalendarNew />
      </Route>
      <Route exact path="/robocalendar/:id">
        <RoboCalendarInfo />
      </Route>
      <Route exact path="/qrscan">
        <QrScan />
      </Route>
      <Route exact path="/boarding/:id">
        <BoardingTicket />
      </Route>
      <Route exact path="/boardingqrscan">
        <BoardingQrScan />
      </Route>
      <Route exact path="/carsequence">
        <CarSequenceList />
      </Route>
      <Route exact path="/userdetail">
        <UserDetail />
      </Route>
      <Route exact path="/accounts/kakao/callback/react">
        <OAuth2RedirectHandler />
      </Route>
      <Route exact path="/accounts/jtnet/callback">
        <Jtnet2RedirectHandler />
      </Route>
      <Route exact path="/accounts/jtnet/callback/general">
        <JtnetGeneral2RedirectHandler />
      </Route>
      <Route exact path="/signupconfirm">
        <SignupConfirm />
      </Route>
      <Route exact path="/auth/reset/:uid/:token" component={PasswordResetView}/>
      <Route exact path="/notes/new">
        <NoteNew />
      </Route>
      <Route exact path="/notes/:id">
        <NoteInfo />
      </Route>
      <Route exact path="/notes">
        <Notes />
      </Route>
      <Route exact path="/drivingrecord">
        <DrivingRecord />
      </Route>
      <Route exact path="/receiptmaster/new">
        <ReceiptMasterNew />
      </Route>
      <Route exact path="/receiptmaster/:id">
        <ReceiptMasterInfo />
      </Route>
      <Route exact path="/receiptmaster">
        <ReceiptMaster />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}