import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Stack, Modal, Button, ButtonGroup } from 'react-bootstrap';
import { useParams, useHistory, Link } from "react-router-dom";
import axiosInstance from "../axiosApi";
import { onError } from "../libs/errorLib";
import moment from "moment";
import 'moment/locale/ko';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useTranslation } from 'react-i18next';
import DropdownSelector from "../components/DropdownSelector";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { IconContext } from "react-icons";

function BordingOnOffCompleteModal(props) {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  //console.log(props.bookId);
  //console.log(props.ingStatus);

  async function updateBoarding(qrUrl, book) {
    return axiosInstance.put(qrUrl, book).then(function(response) {
      if ((props.ingStatus === "E") && (response.data.ing_status === "F")) {
        setMessage("하차 처리가 완료 되었습니다.")
      } else if ((props.ingStatus < "E") && (response.data.ing_status === "E")) {
        setMessage("승차 처리가 완료 되었습니다.")
      } else if ((props.ingStatus === "F") && (response.data.ing_status === "E")) {
        setMessage("하차취소 처리가 완료 되었습니다.")
      } else if ((props.ingStatus === "E") && (response.data.ing_status === "A")) {
        setMessage("승차취소 처리가 완료 되었습니다.")
      } else {
        setMessage("처리에 실패하였습니다");
      }
      return;
    }).catch(function (error) {
      if ( error.response.status === 444 ) {
        onError(error.response.data.message);
      } else {
        setMessage("처리에 실패하였습니다");
      }
    }); 
  }

  const onChangeHandler = (arg_ticket, event) => {
    let qrUrl = "";
    let postData = {};
    qrUrl = `/robotaxi/bookingcar/${props.bookId}/`
    if (arg_ticket === "A") {
      postData = {
        ing_status: arg_ticket,
        rs_date: moment(new Date('1900-01-01T00:00:00')).format('YYYY-MM-DDTHH:mm:ss'),
        re_date: moment(new Date('1900-01-01T00:00:00')).format('YYYY-MM-DDTHH:mm:ss'),
      }
    } else if (arg_ticket === "E") {
      postData = {
        ing_status: arg_ticket,
        re_date: moment(new Date('1900-01-01T00:00:00')).format('YYYY-MM-DDTHH:mm:ss'),
      }
    } else {
      postData = {
        ing_status: arg_ticket,
      }
    }
    updateBoarding(qrUrl, postData);
  }

  const onExitProcess = () => {
    setMessage("");
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onExit={onExitProcess}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          승/하차 관리
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <ButtonGroup size="lg" className="mb-2">
              <Button className="mx-2" variant="outline-primary" onClick={(e) => onChangeHandler("E",e)}>승차</Button>{' '}
              <Button className="mx-2" variant="outline-danger" onClick={(e) => onChangeHandler("A",e)}>승차취소</Button>
            </ButtonGroup>
          </Row>
          <Row>
            <br />
          </Row>
          <Row>
            <ButtonGroup size="lg" className="mb-2">
              <Button className="mx-2"variant="outline-primary" onClick={(e) => onChangeHandler("F",e)}>하차</Button>{' '}
              <Button className="mx-2"variant="outline-danger" onClick={(e) => onChangeHandler("E",e)}>하차취소</Button>
            </ButtonGroup>
          </Row>
          <Row>
            <br />
          </Row>
          <Row>
            { message }
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function CarSequenceList() {
  const [bookingCar, setBookingCar] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();
  const [carOptions, setCarOptions] = useState([]);
  const [car, setCar] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [bookId, setBookId] = useState("");
  const [ingStatus, setIngStatus] = useState("");
  
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <IconContext.Provider
          value={{style: { color: '#A4BEF3', fontSize: '30px' }}}
        >
          <div>
            <FaCloudDownloadAlt onClick={ handleClick } />
            {" "}To CSV
          </div>
      </IconContext.Provider>
        //<button className="btn btn-success" onClick={ handleClick }>다운로드 CSV</button>
    );
  };

  const columns = [
    {
        dataField: "car",
        text: t('COL_CAR')
    },
    {
        dataField: "s_date",
        text: t('GETON_EXP')
    },
    {
        dataField: "rs_date",
        text: t('GETON')
    },
    {
        dataField: "s_stationname",
        text: t('GETONSPOT')
    },
    {
        dataField: "e_date",
        text: t('GETOFF_EXP')
    },
    {
        dataField: "re_date",
        text: t('GETOFF')
    },
    {
        dataField: "e_stationname",
        text: t('GETOFFSPOT')
    },
    {
        dataField: "nickname",
        text: t('BOOKER')
    },
    {
        dataField: "ing_status",
        text: t('COL_STATUS')
    }
  ];
  
  useEffect(() => {
    onLoad();
  }, []);
  
  async function onLoad() {
    setBookingCar([]);
    try {
      axiosInstance.get('/robotaxi/bookingcar/sequence/').then(function(response) {
          response.data.map( (data_row) => {
              let book = {};
              book['id'] = data_row.id;
              book['car'] = data_row.car.carname;
              book['s_date'] = moment(new Date(data_row.s_date)).format("MM.DD(ddd) HH:mm");
              book['rs_date'] = moment(new Date(data_row.rs_date)).format("YYYY.MM.DD") === "1900.01.01" ? "" : moment(new Date(data_row.rs_date)).format("MM.DD(ddd) HH:mm");
              book['s_stationname'] = data_row.s_station.stationname;
              book['e_date'] = moment(new Date(data_row.e_date)).format("MM.DD(ddd) HH:mm");
              book['re_date'] = moment(new Date(data_row.re_date)).format("YYYY.MM.DD") === "1900.01.01" ? "" : moment(new Date(data_row.re_date)).format("MM.DD(ddd) HH:mm");
              book['e_stationname'] = data_row.e_station.stationname;
              book['nickname'] = (data_row.user.nickname === '' ? data_row.user.email.split('@')[0] : data_row.user.nickname)
              book['ing_status'] = data_row.ing_status_name;
              book['ing_status_code'] = data_row.ing_status;
              setBookingCar(bookingCar => [...bookingCar, book]);
              //setBookingCar(bookingCar.concat(book));
          });
      }).catch(function (error) {
        if ( error.response.status === 444 ) {
          onError(error.response.data.message);
        } else {
          onError(error);
        }
      });
      
      await axiosInstance.get('/robotaxi/robocar/').then(function(response) {
        //console.log(response.data);
        const cars = response.data;
        setCarOptions(cars.map( data => ({ name: data.carname, id: data.id, car:data} ) ));
      }).catch(function (error) {
        if ( error.response.status === 444 ) {
          onError(error.response.data.message);
        } else {
          onError(error);
        }
      });
       
    } catch (e) {
      onError(e);
    }
  }

  //const selectRowProp = {
  //  mode: 'radio',
  //  clickToSelect: true,
  //  unselectable: [2],
  //  selected: [1],
  //  onSelect: onSelectRow,
  //   bgColor: 'skyblue'
  //};
  
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
        //console.log(`clicked on row with index: ${rowIndex}`);
        setBookId(row.id);
        setIngStatus(row.ing_status_code);
    }
  }

  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    style: { backgroundColor: '#c8e6c9' },
    onSelect: (row, isSelect, rowIndex, e) => {
      //console.log(row.id);
      //console.log(rowIndex);
      showModal();
    }
  }
  
  function onChangeCar(event) {
    const result = carOptions.find(option => Number(option.id) === Number(event.target.value));
    console.log(result);
    if (typeof(result) === 'undefined') {
      setCar("");
    } else {
      setCar(result.car);
    }
    
    setBookingCar([]);
    try {
        axiosInstance.get('/robotaxi/bookingcar/sequence/',{
                params: {
                    id: typeof(result) === 'undefined' ? '' : result.car.id
                }
        }).then(function(response) {
            response.data.map( (data_row) => {
                let book = {};
                
                book['id'] = data_row.id;
                book['car'] = data_row.car.carname;
                book['s_date'] = moment(new Date(data_row.s_date)).format("MM.DD(ddd) HH:mm");
                book['s_stationname'] = data_row.s_station.stationname;
                book['e_date'] = moment(new Date(data_row.e_date)).format("MM.DD(ddd) HH:mm");
                book['e_stationname'] = data_row.e_station.stationname;
                book['nickname'] = (data_row.user.nickname === '' ? data_row.user.email : data_row.user.nickname)
                book['ing_status'] = data_row.ing_status_name;
                setBookingCar(bookingCar => [...bookingCar, book]);
                //setBookingCar(bookingCar.concat(book));
            });
        }).catch(function (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(error);
          }
        });
         
      } catch (e) {
        onError(e);
      }
  };
  
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group" role="group">
      {
        options.map((option) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={ option.text }
              type="button"
              onClick={ () => onSizePerPageChange(option.page) }
              className={ `btn ${isSelect ? 'btn-dark' : 'btn-secondary'}` }
            >
              { option.text }
            </button>
          );
        })
      }
    </div>
  );

  const options = {
    sizePerPageRenderer
  };

  function showModal() {
    setModalShow(true);
  }
  
  function hideModal() {
    setModalShow(false);
    onLoad();
  }

  return (
  <Stack gap={3}>
      {/*
      <h6>현재시간에서 3시간 전부터 등록된 예약건을 조회합니다.</h6>
      */}
      <div className="bg-light border">
        <ToolkitProvider
          keyField="s_date"
          data={ bookingCar }
          columns={ columns }
          exportCSV
        >
          {
            props => (
              <div>
                <Container>
                  <Row>
                    <Col>
                      <DropdownSelector
                        labelName={t('CAR')}
                        options={carOptions}
                        defaultValue={car.id}
                        onChange={onChangeCar}
                      />
                    </Col>
                    <Col>
                      <MyExportCSV { ...props.csvProps } />
                    </Col>
                  </Row>
                </Container>
                <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={bookingCar}
                    columns={columns}
                    striped
                    hover
                    condensed
                    bordered={ false }
                    rowEvents={ rowEvents }
                    selectRow={ selectRow }
                    pagination={paginationFactory(options)}
                />
              </div>
            )
          }
        </ToolkitProvider>

      </div>
      <div>
        <BordingOnOffCompleteModal
          show={modalShow}
          onHide={hideModal}
          bookId={bookId}
          ingStatus={ingStatus}
        />
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default CarSequenceList;