import React, { useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { BsPencilSquare } from "react-icons/bs";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import { useTranslation } from 'react-i18next';

function Station() {
  const { t } = useTranslation();
  const [stations, setStations] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
      
      try {
        axiosInstance.get('/robotaxi/station/').then(function(response) {
          const stations = response.data;
          setStations(stations);
        }).catch(function (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(error);
          }
        });
      } catch (e) {
        onError(e);
      }
      
      setIsLoading(false);
    }
    
    onLoad();
  }, [isAuthenticated]);
  
  function renderStationsList(stations) {
    return (
      <>
        <LinkContainer to="/station/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">Create a New Station</span>
          </ListGroup.Item>
        </LinkContainer>
        {stations.map(({ id, stationname, site_lat, site_lng, crt_date }) => (
          <LinkContainer key={id} to={`/station/${id}`}>
            <ListGroup.Item action>
              <span className="font-weight-bold">
                {stationname}
              </span>
              <br />
              <span className="font-weight-bold">
                {site_lat}
              </span>
              <br />
              <span className="font-weight-bold">
                {site_lng}
              </span>
              <br />
              <br />
              <span className="text-muted">
                Created: {new Date(crt_date).toLocaleString()}
              </span>
            </ListGroup.Item>
          </LinkContainer>
        ))}
      </>
    );
  }
  
  return (
  <Stack gap={3}>
    <div className="bg-light border titlesubject">
      {t('STATIONMGT')}
    </div>
    <div className="routes">
      <ListGroup>{!isLoading && renderStationsList(stations)}</ListGroup>
    </div>
      
    
    <div>
      <br /><br /><br />
    </div>
  </Stack>
  );
}

export default Station;