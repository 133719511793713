// redux > modules > user.js
import React, { useState } from "react";
import axios from 'axios'
import axiosInstance from "../../axiosApi";
import { onError } from "../../libs/errorLib";
import config from "../../config";
import { setToken } from "../../firebase";

const KakaoLogin = (code, history, userHasAuthenticated, setIsLoading, setUser) => {

    return function (dispatch, getState) {
        axios.get(`${config.DJANGO_URL}/accounts/kakao/callback/react/`,
            {
              params:{
                  code:code,
              },
            }
          ).then((response) => {
            //get token
            console.log(response.data);

            axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access_token;
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);
            localStorage.setItem('access_user', JSON.stringify(response.data.user));
            setUser(response.data.user);
            userHasAuthenticated(true);
            setToken('robotaxi',response.data.user.email);
            history.push("/");
            
            //window.alert("Login success...");
            //get token -> change page to HOME
            //history.replace("/"); 
        
        }).catch((error) => {
            //console.log(err);
            if ( error.response.status === 444 ) {
                onError(error.response.data.message);
              } else {
                console.log(error);
              }
            userHasAuthenticated(false);
            setIsLoading(false);
        });
    }
};

const actionCreators = { KakaoLogin }; 

export { actionCreators }