import React, { useRef, useState, useEffect } from "react";
import { Stack } from 'react-bootstrap';
import {Form, Row, Col, Container, ButtonGroup, Offcanvas, Button, Modal} from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import axiosInstance from "../axiosApi";
import { onError } from "../libs/errorLib";
import { useAppContext } from "../libs/contextLib";
import moment from "moment";
import 'moment/locale/ko';
import { useTranslation } from 'react-i18next';
import { Spinner } from "react-bootstrap";
import config from "../config";

function BillCompleteModal(props) {
  const { t } = useTranslation();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('BILL_TITLE')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{t('BILL_STATUS')}</h4>
        <p>
          {t('BILL_RESULT_OK')}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function JtnetGeneral2RedirectHandler() {
  const history = useHistory();
  const { t } = useTranslation();
  const [modalShow, setModalShow] = React.useState(false);
  const inputRef = useRef(null);
  
  // 인가코드
  let code = new URL(window.location.href).searchParams.get("imp_success");
  let imp_uid = new URL(window.location.href).searchParams.get("imp_uid");
  let merchant_uid = new URL(window.location.href).searchParams.get("merchant_uid");
  let error_msg = new URL(window.location.href).searchParams.get("error_msg");
  
  React.useEffect(async () => {
    //console.log(window.location.href);
    if (code === 'true') {
      try {
        const response = await axiosInstance.post(`/robotaxi/bookingcarpay/`, {
          imp_uid: imp_uid,
          merchant_uid: merchant_uid
        });
        showModal();
      } catch(error) {
        if ( error.response.status === 444 ) {
          onError(error.response.data.message);
        } else {
          //await deletePayError();
          history.push("/bookingcar");
          onError("결재가 완료되지 않았습니다. 예약내역에서 결재 또는 예약취소 해주세요.(mobile1)");
        }
      };
    } else {
      //await deletePayError();
      history.push("/bookingcar");
      onError("결재가 완료되지 않았습니다. 예약내역에서 결재 또는 예약취소 해주세요.(mobile2)");
    }
  }, []);
  
  // 오류발생하여 결재처리시 예외처리로 해결함_2022.05.14
  async function deletePayError() {
    try {
      const response = await axiosInstance.post(`/robotaxi/bookingcarpayerror/`, {
        imp_uid: imp_uid,
        merchant_uid: merchant_uid
      });
      alert(error_msg);
      history.push("/bookingcar");
    } catch(error) {
      if ( error.response.status === 444 ) {
        onError(error.response.data.message);
      } else {
        onError(t('PROCESS_FAIL'));
      }
    };
  }

  function showModal() {
    console.log("show modal");
    setModalShow(true);
  }
  
  function hideModal() {
    console.log("show modal");
    setModalShow(false);
    history.push("/bookingcar");
  }
  
  return (
  <Stack gap={3}>
    <div>
        <Container>
            <Row className="justify-content-center">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Row>
        </Container>
    </div>
    <div>
      <BillCompleteModal
        show={modalShow}
        onHide={hideModal}
      />
    </div>
    <div>
        <br /><br /><br />
    </div>
  </Stack>
  );
}

export default JtnetGeneral2RedirectHandler;