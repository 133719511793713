import React, { useState, useEffect } from "react";
import { Nav, Navbar, Stack, Badge } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../libs/contextLib";
import RouteMapDisplay from "../components/RouteMapDisplay";
import axiosInstance from "../axiosApi";
import { onError } from "../libs/errorLib";
import GoogleMapAPI from "../components/GoogleMapAPI";
import GoogleMapReactAPI from "../components/GoogleMapReactAPI";
import Destination from "./Destination";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import {usePosition} from '../usePosition';

export default function BookingStation() {
  const { routeAppContext } = useAppContext();
  const { setRouteAppContext } = useAppContext();
  const [route, setRoute] = useState([]);
  const { t } = useTranslation();
  const history = useHistory();
  const { routeMap, setRouteMap } = useAppContext();
  const [paths, setPaths] = useState([]);
  const {latitude, longitude, error} = usePosition(true, {
    enableHighAccuracy: true,
    timeout: Infinity,
    maximumAge: 0,
  });
  const [isBillingKey, setIsBillingKey] = useState(false);
  const [tempUser, setTempUser] = useState(JSON.parse(localStorage.getItem("access_user")));

  setRouteAppContext(routeAppContext);
  
  useEffect(() => {
    if ( routeAppContext === '' ) {
      history.push("/");
      return;
    }
    
    async function onLoad() {
      try {
        if (routeAppContext !== '') {
          axiosInstance.get('/robotaxi/route/',{
                    params: {id: routeAppContext.id}
                }).then(function(response) {
            //console.log("BookingStation onload event" + routeAppContext.routename);
            //console.log(response.data);
            setRoute(response.data);
          
          }).catch(function (error) {
            if ( error.response.status === 444 ) {
              onError(error.response.data.message);
            } else {
              onError(error);
            }
          }); 

          axiosInstance.get('/robotaxi/routemap/',{
              params: {id: routeAppContext.id}
          }).then(function(response) {
            setRouteMap(response.data);

            response.data.map( (data_row) => {
              let path = {};
        
              path['lat'] = data_row.station.site_lat
              path['lng'] = data_row.station.site_lng
              setPaths(paths => [...paths, path])
            });
            
            let f_path = {};
            f_path['lat'] = response.data[0].station.site_lat;
            f_path['lng'] = response.data[0].station.site_lng;
            setPaths(paths => [...paths, f_path])

          }).catch(function (error) {
              //console.log(error);
              if ( error.response.status === 444 ) {
                onError(error.response.data.message);
              }
          });

          await axiosInstance.get(`/accounts/user/${tempUser.pk}/`).then(function(response) {
            if (response.data.status === 'CARD') {
              setIsBillingKey(true);
            } else {
              setIsBillingKey(false);
            }
          }).catch(function (error) {
            if ( error.response.status === 444 ) {
              onError(error.response.data.message);
            } else {
              onError(t('PROCESS_FAIL'));
            }
          });

        }
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, []);
  
  return (
      <Stack gap={3}>
        <div className="bg-light">
              <RouteMapDisplay route={route} />
        </div>
          
        <div className="bg-light border">
          <Destination />
        </div>
        <div className="bg-light border">
                {route.map((data) => {
                    return (
                        <GoogleMapAPI ref_lat={data.ref_lat} ref_lng={data.ref_lng} defalt_zoom={data.defalt_zoom} 
                        routeMap={routeMap} latitude={latitude} longitude={longitude} />
                    );
                })}
        </div>
        <div>
          <br /><br /><br />
        </div>
      </Stack>
  );
}