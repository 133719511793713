import React, { useRef, useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import DropdownSelector from "../components/DropdownSelector";
import { useTranslation } from 'react-i18next';

function RouteMapInfo() {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [routemap, setRoutemap] = useState(null);
  const [sequence, setSequence] = useState("");
  const [leadtime, setLeadtime] = useState("");
  const [rundistance, setRundistance] = useState("");
  const [charge, setCharge] = useState("");
  const [route, setRoute] = useState("");
  const [station, setStation] = useState("");
  
  const [routeSel, setRouteSel] = useState("");
  const [stationSel, setStationSel] = useState("");
  
  const [routeOptions, setRouteOptions] = useState([]);
  const [stationOptions, setStationOptions] = useState([]);
  
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  
  useEffect(() => {
    async function onLoad() {
      try {
        await axiosInstance.get(`/robotaxi/routemap/${id}/`).then(function(response) {
          console.log(response.data);
          const routemap = response.data;
          setRoutemap(routemap);
          setSequence(routemap.sequence);
          setLeadtime(routemap.leadtime);
          setRundistance(routemap.rundistance);
          setCharge(routemap.charge);
          setRoute(routemap.route);
          setStation(routemap.station);
          setRouteSel(routemap.route.id);
          setStationSel(routemap.station.id);
        }).catch(function (error) {
          //console.log(error);
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(error);
          }
        });
        
        await axiosInstance.get('/robotaxi/route/').then(function(response) {
          //console.log(response.data);
          const routes = response.data;
          setRouteOptions(routes.map( data => ({ name: data.routename, id: data.id, route:data} ) ));
        }).catch(function (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(error);
          }
        });
        
        await axiosInstance.get('/robotaxi/station/').then(function(response) {
          //console.log(response.data);
          const stations = response.data;
          setStationOptions(stations.map( data => ({ name: data.stationname, id: data.id, station:data }) ));
        }).catch(function (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(error);
          }
        });
        
      } catch (e) {
        onError(e);
      }
    }
    
    onLoad();
  }, [id]);
  
  function validateForm() {
    return (
      true
      //carname.length > 0
    );
  }
  
  async function saveRoutemap(routemap) {
    console.log(routemap);
    axiosInstance.put(`/robotaxi/routemap/${id}/`, routemap).then(function(response) {
          return;
        }).catch(function (error) {
          //console.log(error);
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(error);
          }
          setIsLoading(false);
        }); 
  }
  
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await saveRoutemap({
        id: routemap.id,
        sequence: sequence,
        leadtime: leadtime,
        charge: charge,
        route: route,
        station: station,
        rundistance: rundistance
      });
    
      history.push("/routemap");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function deleteRoutemap() {
    return axiosInstance.delete(`/robotaxi/routemap/${id}/`, routemap).then(function(response) {
      return;
    }).catch(function (error) {
      //console.log(error);
      if ( error.response.status === 444 ) {
        onError(error.response.data.message);
      } else {
        onError(error);
      }
      setIsLoading(false);
    }); 
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this note?"
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteRoutemap();
      
      history.push("/routemap");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }
  
  function onChangeRoute(event) {
    const result = routeOptions.find(option => Number(option.id) === Number(event.target.value));
    setRoute(result.route);;
    //this.setState({ selectedValue: event.target.value });
    setRouteSel(event.target.value);
  };
  
  function onChangeStation(event) {
    const result = stationOptions.find(option => Number(option.id) === Number(event.target.value));
    setStation(result.station);
    //this.setState({ selectedValue: event.target.value });
    setStationSel(event.target.value);
  };
  
  return (
  <Stack gap={3}>
      <div className="bg-light border">
        
        <div className="Notes">
          {routemap && (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="sequence">
                <Form.Label>순서</Form.Label>
                <Form.Control
                  type="text"
                  value={sequence}
                  onChange={(e) => setSequence(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="route">
                <DropdownSelector
                        labelName="노선"
                        options={routeOptions}
                        defaultValue={routeSel}
                        onChange={onChangeRoute}
                />
              </Form.Group>
              <Form.Group controlId="station">
                <DropdownSelector
                        labelName="정류장"
                        options={stationOptions}
                        defaultValue={stationSel}
                        onChange={onChangeStation}
                />
              </Form.Group>
              <Form.Group controlId="leadtime">
                <Form.Label>이동시간(분)</Form.Label>
                <Form.Control
                  type="text"
                  value={leadtime}
                  onChange={(e) => setLeadtime(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="charge">
                <Form.Label>요금(원)</Form.Label>
                <Form.Control
                  type="text"
                  value={charge}
                  onChange={(e) => setCharge(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="rundistance">
                <Form.Label>이동거리(km)</Form.Label>
                <Form.Control
                  type="text"
                  value={rundistance}
                  onChange={(e) => setRundistance(e.target.value)}
                />
              </Form.Group>
              <LoaderButton
                block
                size="lg"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Save
              </LoaderButton>
              <LoaderButton
                block
                size="lg"
                variant="danger"
                onClick={handleDelete}
                isLoading={isDeleting}
              >
                Delete
              </LoaderButton>
            </Form>
          )}
        </div>
        
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default RouteMapInfo;