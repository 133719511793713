import React, { useRef, useState, useEffect } from "react";
import { Stack } from 'react-bootstrap';
import {Form, Row, Col, Container, ButtonGroup, Offcanvas, Button, Modal} from "react-bootstrap";
import styles from "./BillRoute.module.css";
import { useParams, useHistory, useLocation } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import BillingForm from "../components/BillingForm";
import axiosInstance from "../axiosApi";
import { onError } from "../libs/errorLib";
import { useAppContext } from "../libs/contextLib";
import { FaHandPointRight } from "react-icons/fa";
import moment from "moment";
import 'moment/locale/ko';
import { useTranslation } from 'react-i18next';
import config from "../config";

function BillCompleteModal(props) {
  const { t } = useTranslation();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('BILL_TITLE')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{t('BILL_STATUS')}</h4>
        <p>
          {t('BILL_RESULT_OK')}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function BillRoute() {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isBillingKey, setIsBillingKey] = useState(false);
  const [tempUser, setTempUser] = useState(JSON.parse(localStorage.getItem("access_user")));
  const history = useHistory();
  const location = useLocation();
  const [bookDate, setBookDate] = useState('');
  
  const { strStationAppContext } = useAppContext();
  const { endStationAppContext } = useAppContext();
  
  const { strSingleDateAppContext } = useAppContext();
  const { strRoundDateAppContext } = useAppContext();
  
  const { endSingleDateAppContext } = useAppContext();
  const { endRoundDateAppContext } = useAppContext();
  
  const { chargeAppContext } = useAppContext();
  const { ticketGubunAppContext } = useAppContext();
  const { carAppContext } = useAppContext();
  const { carRoundAppContext } = useAppContext();

  const { setTicketGubunAppContext } = useAppContext();
  const { setStrRoundDateAppContext } = useAppContext();
  const { setEndRoundDateAppContext } = useAppContext();
  const { setStrStationAppContext } = useAppContext();
  const { setEndStationAppContext } = useAppContext();
  const { setStrSingleDateAppContext } = useAppContext();
  const { setEndSingleDateAppContext } = useAppContext();
  const { setCarAppContext } = useAppContext();
  const { setCarRoundAppContext } = useAppContext();
  
  const { routeAppContext } = useAppContext();
  const { t } = useTranslation();
  const [modalShow, setModalShow] = React.useState(false);
  const inputRef = useRef(null);
  
  useEffect(() => {

    async function onLoad() {
      try {
        axiosInstance.get(`/accounts/user/${tempUser.pk}/`).then(function(response) {
          if (response.data.status === 'CARD') {
            setIsBillingKey(true);
          } else {
            setIsBillingKey(false);
          }
        }).catch(function (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(t('PROCESS_FAIL'));
          }
        });

        axiosInstance.get('/robotaxi/basicinfo/').then(function(response) {
          console.log(response.data.current_datetime);
          setBookDate(response.data.current_datetime);
        }).catch(function (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(t('PROCESS_FAIL'));
          }
        });
      } catch (e) {
        onError(t('PROCESS_FAIL'));
      }
    }
    
    onLoad();
  }, []);

  function validateForm() {
    return String(endSingleDateAppContext).length > 0 && chargeAppContext.length > 0;
  }
  
  async function saveBooking(data) {
    try {
      const response = await axiosInstance.post(`/robotaxi/bookingcar/`, data['S']);
      if (ticketGubunAppContext === 'R') {
        const response2 =  await axiosInstance.post(`/robotaxi/bookingcar/`, data['R']);
      } 
      console.log(data);
      if (typeof response === 'object' && response.data !== undefined) {
        if (bookDate === response.data.book_date) {
          await handleSubmitCallback();
        } else {
          onError("예약처리중에 문제가 있었습니다. 새로고침후 다시 시도해 보시기 바랍니다.");
        }
      } else {
        onError("예약에 실패하였습니다.");
      }
      
    } catch(error) {
      console.log(error.response);
      if ( error.response.status === 412 ) {
        onError("예약에 실패하였습니다. : " + error.response.data);
      } else if ( error.response.status === 444 ) {
        onError(error.response.data.message);
      } else {
        onError("결재에러입니다. 관리자(e-mail:raxi@sonnet.ai)에게 문의 주세요.");
      }
      setIsLoading(false);
    }; 
  }
  
  async function handleSubmit(event) {
    event.preventDefault();
    var dictBook = {};
    var car_direction = 'A';
    var car_direction_round = 'B';
    setIsLoading(true);
    
    if (Number(strStationAppContext.sequence) < Number(endStationAppContext.sequence)) {
      car_direction = 'A';
      car_direction_round = 'B';
    } else {
      car_direction = 'B';
      car_direction_round = 'A';
    }

    if (ticketGubunAppContext === 'R') {
        dictBook['S'] = {"ing_status": "J",
            "routename": routeAppContext.id,
            "book_date": bookDate,
            "s_station": strStationAppContext.station.id,
            "s_date": moment(strSingleDateAppContext).format('YYYY-MM-DDTHH:mm:ss'),
            "e_station": endStationAppContext.station.id,
            "e_date": moment(endSingleDateAppContext).format('YYYY-MM-DDTHH:mm:ss'),
            "leadtime": location.state.singleLeadTime,
            "rundistance": location.state.singleRunDistance,
            "charge": chargeAppContext,
            "est_date": moment().format('YYYY-MM-DDTHH:mm:ss'),
            "car_direction":car_direction,
            "rs_date": moment(new Date('1900-01-01T00:00:00')).format('YYYY-MM-DDTHH:mm:ss'),
            "re_date": moment(new Date('1900-01-01T00:00:00')).format('YYYY-MM-DDTHH:mm:ss'),
            "ticket": 'S',
            "s_sequence": strStationAppContext.sequence,
            "e_sequence": endStationAppContext.sequence,
            "car": carAppContext.id
        };
        dictBook['R'] = {"ing_status": "J",
            "routename": routeAppContext.id,
            "book_date": bookDate,
            "s_station": endStationAppContext.station.id,
            "s_date": moment(strRoundDateAppContext).format('YYYY-MM-DDTHH:mm:ss'),
            "e_station": strStationAppContext.station.id,
            "e_date": moment(endRoundDateAppContext).format('YYYY-MM-DDTHH:mm:ss'),
            "leadtime": location.state.roundLeadTime,
            "rundistance": location.state.roundRunDistance,
            "charge": chargeAppContext,
            "est_date": moment().format('YYYY-MM-DDTHH:mm:ss'),
            "car_direction":car_direction_round,
            "rs_date": moment(new Date('1900-01-01T00:00:00')).format('YYYY-MM-DDTHH:mm:ss'),
            "re_date": moment(new Date('1900-01-01T00:00:00')).format('YYYY-MM-DDTHH:mm:ss'),
            "ticket": 'R',
            "s_sequence": endStationAppContext.sequence,
            "e_sequence": strStationAppContext.sequence,
            "car": carRoundAppContext.id
            };
    }
    else {
        dictBook['S'] = {"ing_status": "J",
            "routename": routeAppContext.id,
            "book_date": bookDate,
            "s_station": strStationAppContext.station.id,
            "s_date": moment(strSingleDateAppContext).format('YYYY-MM-DDTHH:mm:ss'),
            "e_station": endStationAppContext.station.id,
            "e_date": moment(endSingleDateAppContext).format('YYYY-MM-DDTHH:mm:ss'),
            "leadtime": location.state.singleLeadTime,
            "rundistance": location.state.singleRunDistance,
            "charge": chargeAppContext,
            "est_date": moment().format('YYYY-MM-DDTHH:mm:ss'),
            "car_direction":car_direction,
            "rs_date": moment(new Date('1900-01-01T00:00:00')).format('YYYY-MM-DDTHH:mm:ss'),
            "re_date": moment(new Date('1900-01-01T00:00:00')).format('YYYY-MM-DDTHH:mm:ss'),
            "ticket": 'S',
            "s_sequence": strStationAppContext.sequence,
            "e_sequence": endStationAppContext.sequence,
            "car": carAppContext.id
        };
    }
    
    try {
        //console.log(dictBook);
        await saveBooking(dictBook);
    } catch (e) {
        onError(e);
        setIsLoading(false);
    }
  }

  async function handleSubmitCallback() {
    let imp_uid = '';
    let pay_amount = 3000;
    var merchant_uid = bookDate;
    
    if (isBillingKey) {
      //등록된 카드로 정기결재 처리
      imp_uid = '';

      try {
        const response = await axiosInstance.post(`/robotaxi/bookingcarpay/`, {
          imp_uid: imp_uid,
          merchant_uid: merchant_uid
        });
        showModal();
      } catch(error) {
        if ( error.response.status === 444 ) {
          onError(error.response.data.message);
        } else {
          onError(t('PROCESS_FAIL'));
        }
      };

    } else {
      // call paymant process
      if (ticketGubunAppContext === 'R') {
        pay_amount = 6000;
      } else {
        pay_amount = 3000;
      }
      //Jtnet 결재창 호출
      var IMP = window.IMP;
      IMP.init("imp82530589");

      IMP.request_pay({ // param
        pg : 'jtnet',
        pay_method : 'card', // 'card'만 지원됩니다.
        merchant_uid: merchant_uid, // 상점에서 관리하는 주문 번호
        name : '주문명: Raxi자율주행차운임',
        amount : pay_amount,
        buyer_email : tempUser.email,
        buyer_name : '',
        buyer_tel : '',
        m_redirect_url : `${config.REDIRECT_URI_JTNET_GENERAL}`
      }, function (rsp) { // callback
          //console.log(rsp);
          
          if (rsp.success) {
            imp_uid = rsp.imp_uid;
            axiosInstance.post(`/robotaxi/bookingcarpay/`, {
              imp_uid: imp_uid,
              merchant_uid: merchant_uid
            }).then(function(response) {
              showModal();
            }).catch(function (error) {
              console.log(error);
              //deletePayError(imp_uid, merchant_uid, rsp);
              setIsLoading(false);
              history.push("/bookingcar");
              onError("결재가 완료되지 않았습니다. 예약내역에서 결재 또는 예약취소 해주세요.(pc1)");
            });
          } else {
            //deletePayError(imp_uid, merchant_uid, rsp);
            setIsLoading(false);
            history.push("/bookingcar");
            onError("결재가 완료되지 않았습니다. 예약내역에서 결재 또는 예약취소 해주세요.(pc2)");
          }
          
      }); 
    }
  }
  
  // 오류발생하여 결재처리시 예외처리로 해결함_2022.05.14
  async function deletePayError(imp_uid, merchant_uid, error_msg) {
    try {
      const response = await axiosInstance.post(`/robotaxi/bookingcarpayerror/`, {
        imp_uid: imp_uid,
        merchant_uid: merchant_uid
      });
      history.push("/bookingstation");
    } catch(error) {
      if ( error.response.status === 444 ) {
        onError(error.response.data.message);
      } else {
        onError(t('PROCESS_FAIL'));
      }
    };
  }

  function showModal() {
    setModalShow(true);
  }
  
  function hideModal() {
    setModalShow(false);
    
    setTicketGubunAppContext("S");
    setStrRoundDateAppContext("");
    setEndRoundDateAppContext("");
    setStrStationAppContext("");
    setEndStationAppContext("");
    setStrSingleDateAppContext("");
    setEndSingleDateAppContext("");
    setCarAppContext("");
    setCarRoundAppContext("");

    history.push("/bookingcar");
  }
  
  return (
  <Stack gap={3}>
    <div>
      <Form className="form" onSubmit={handleSubmit}>
        <Container className="oneway">
        <Row>
          <Col sm={6} >
            <Form.Group as={Row} controlId="formHorizontal">
                <Col className="my-1" xs={4} md={4}>
                    <Form.Label>{t('DEPARTSTATION')}</Form.Label>
                </Col>
                <Col className="my-1" xs={8} md={8}>
                    <Form.Control 
                        type="text" 
                        name="s_station"
                        size="sm"
                        placeholder={t('SELECTDEPART')}
                        readOnly
                        value={strStationAppContext === '' ? '' : strStationAppContext.station.stationname}
                    />
                </Col>
            </Form.Group>
          </Col>
          <Col sm={6} >
            <Form.Group as={Row} controlId="formHorizontal">
                <Col className="my-1" xs={4} md={4}>
                    <Form.Label>{t('DEPARTTIME')}</Form.Label>
                </Col>
                <Col className="my-1" xs={8} md={8}>
                    <Form.Control 
                        size="sm" 
                        type="text" 
                        name="s_date"
                        readOnly
                        value={strSingleDateAppContext === '' ? '' : moment(strSingleDateAppContext).format("YY.MM.DD(ddd) HH:mm")} />
                </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6} >
            <Form.Group as={Row} controlId="formHorizontal">
                <Col className="my-1" xs={4} md={4}>
                    <Form.Label>{t('ARRIVALSTATION')}</Form.Label>
                </Col>
                <Col className="my-1" xs={8} md={8}>
                    <Form.Control 
                        type="text" 
                        name="e_station"
                        size="sm"
                        placeholder={t('SELECTARRIVAL')}
                        readOnly
                        value={endStationAppContext === '' ? '' : endStationAppContext.station.stationname}
                    />
                </Col>
            </Form.Group>
          </Col>
          <Col sm={6} >
            <Form.Group as={Row} controlId="formHorizontal">
                <Col className="my-1" xs={4} md={4}>
                    <Form.Label>{t('ARRIVALTIME')}</Form.Label>
                </Col>
                <Col className="my-1" xs={8} md={8}>
                    <Form.Control 
                        size="sm" 
                        type="text" 
                        name="e_date"
                        readOnly
                        value={endSingleDateAppContext === '' ? '' : moment(endSingleDateAppContext).format("YY.MM.DD(ddd) HH:mm")} />
                </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6} >
            <Form.Group as={Row} controlId="formHorizontal">
                <Col className="my-1" xs={4} md={4}>
                    <Form.Label>{t('CAR')}</Form.Label>
                </Col>
                <Col className="my-1" xs={8} md={8}>
                    <Form.Control 
                        type="text" 
                        name="s_car"
                        size="sm"
                        readOnly
                        value={carAppContext === '' ? '' : carAppContext.carname}
                    />
                </Col>
            </Form.Group>
          </Col>
          <Col sm={6} >
            <Form.Group as={Row} controlId="formHorizontal">
                <Col className="my-1" xs={4} md={4}>
                    <Form.Label>{t('FARE')}</Form.Label>
                </Col>
                <Col className="my-1" xs={8} md={8}>
                    <Form.Control 
                        size="sm" 
                        type="text" 
                        name="charge"
                        readOnly
                        value={chargeAppContext === '' ? '' : chargeAppContext} />
                </Col>
            </Form.Group>
          </Col>
        </Row>
        </Container>
        
        {(ticketGubunAppContext === 'R') ? (
        <Container className="roundway">
        <hr/>
        <Row>
          <Col sm={6} >
            <Form.Group as={Row} controlId="formHorizontal">
                <Col className="my-1" xs={4} md={4}>
                    <Form.Label className="text-center">{t('DEPARTSTATION')}</Form.Label>
                </Col>
                <Col className="my-1" xs={8} md={8}>
                    <Form.Control 
                        className="mx-1"
                        type="text" 
                        size="sm"
                        name="rs_station"
                        placeholder={t('SELECTDEPART')}
                        readOnly
                        value={endStationAppContext.station.stationname}
                    />
                </Col>
            </Form.Group>
          </Col>
          <Col sm={6} >
            <Form.Group as={Row} controlId="formHorizontal">
                <Col className="my-1" xs={4} md={4}>
                    <Form.Label>{t('DEPARTTIME')}</Form.Label>
                </Col>
                <Col className="my-1" xs={8} md={8}>
                    <Form.Control 
                        size="sm" 
                        type="text" 
                        name="rs_date"
                        readOnly
                        value={strRoundDateAppContext === '' ? '' : moment(strRoundDateAppContext).format("YY.MM.DD(ddd) HH:mm")} />
                </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6} >
            <Form.Group as={Row} controlId="formHorizontal">
                <Col className="my-1" xs={4} md={4}>
                    <Form.Label>{t('ARRIVALSTATION')}</Form.Label>
                </Col>
                <Col className="my-1" xs={8} md={8}>
                    <Form.Control 
                        type="text" 
                        size="sm"
                        name="re_station"
                        placeholder={t('SELECTARRIVAL')}
                        readOnly
                        value={strStationAppContext === '' ? '' : strStationAppContext.station.stationname}
                    />
                </Col>
            </Form.Group>
          </Col>
          <Col sm={6} >
            <Form.Group as={Row} controlId="formHorizontal">
                <Col className="my-1" xs={4} md={4}>
                    <Form.Label>{t('ARRIVALTIME')}</Form.Label>
                </Col>
                <Col className="my-0" xs={8} md={8}>
                    <Form.Control 
                        size="sm" 
                        type="text" 
                        name="re_date"
                        readOnly
                        value={endRoundDateAppContext === '' ? '' : moment(endRoundDateAppContext).format("YY.MM.DD(ddd) HH:mm")} />
                </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6} >
            <Form.Group as={Row} controlId="formHorizontal">
                <Col className="my-1" xs={4} md={4}>
                    <Form.Label>{t('CAR')}</Form.Label>
                </Col>
                <Col className="my-1" xs={8} md={8}>
                    <Form.Control 
                        type="text" 
                        name="r_car"
                        size="sm"
                        readOnly
                        value={carRoundAppContext === '' ? '' : carRoundAppContext.carname}
                    />
                </Col>
            </Form.Group>
          </Col>
          <Col sm={6} >
            <Form.Group as={Row} controlId="formHorizontal">
                <Col className="my-1" xs={4} md={4}>
                    <Form.Label>{t('FARE')}</Form.Label>
                </Col>
                <Col className="my-1" xs={8} md={8}>
                    <Form.Control 
                        size="sm" 
                        type="text" 
                        name="r_charge"
                        readOnly
                        value={chargeAppContext === '' ? '' : chargeAppContext} />
                </Col>
            </Form.Group>
          </Col>
        </Row>
        </Container>
        ) : (
                <Container />
        )}
        <Row className="mx-1 my-1">
            <LoaderButton
                block
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
            >
                {t('APPROVAL')}
            </LoaderButton>
        </Row>
      </Form>
    </div>
    <div>
      <BillCompleteModal
        show={modalShow}
        onHide={hideModal}
      />
    </div>
    <div>
        <br /><br /><br />
    </div>
  </Stack>
  );
}

export default BillRoute;