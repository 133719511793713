// 리다이렉트될 화면
// OAuth2RedirectHandler.js

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { actionCreators as userActions } from "../redux/modules/user";
import { Spinner } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import axiosInstance from "../axiosApi";
import { onError } from "../libs/errorLib";
import { useTranslation } from 'react-i18next';

//정기결재 모바일 Redirect Url 처리
const Jtnet2RedirectHandler = (props) => {
  const dispatch = useDispatch();
  const { userHasAuthenticated } = useAppContext();
  const { setUser } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [tempUser, setTempUser] = useState(JSON.parse(localStorage.getItem("access_user")));
  const { t } = useTranslation();
  
  // 인가코드
  let code = new URL(window.location.href).searchParams.get("imp_success");

  React.useEffect(async () => {
    if (code) {
        axiosInstance.put(`/accounts/user/${tempUser.pk}/`, {
            email: tempUser.email,
            nickname: tempUser.nickname,
            phone_number: tempUser.phone_number,
            is_staff: tempUser.is_staff,
            is_active: tempUser.is_active,
            user_type: tempUser.email + '_card',
            status: 'CARD'
        }).then(function(response) {
            
        }).catch(function (error) {
            if ( error.response.status === 444 ) {
                onError(error.response.data.message);
            } else {
                onError(t('PROCESS_FAIL'));
            }
            setIsLoading(false);
        }); 
    }
    history.push("/userdetail")
  }, []);

  return (
    <div>
        <Container>
            <Row className="justify-content-center">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Row>
        </Container>
    </div>
  );
};

export default Jtnet2RedirectHandler;