import { Table, NavLink, Image, Jumbotron, Container, Row, Col, Badge, Card } from "react-bootstrap";
import styles from './CompanyInfo.css';
import Green_image from '../images/bg_green_wing_v03_crop.png';
import Gray_image from '../images/bg_gray_wing_v03_crop.png';
import { useTranslation } from 'react-i18next';

export default function CompanyInfo() {
  const { t } = useTranslation();
  
  return (
   <div className="companyinfo_body">
    
    <Container>
      <Row >
        <Col xs={12} md={12} style={{padding:10, marginTop:50, marginBottom:50}}>
          <div><h1><Badge variant="secondary">{t('ABOUTUS')}</Badge></h1></div>
        </Col>
        <Col/>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <h4>
            {t('HOME_CI_003')}
            <p/>
          </h4>
        </Col>
        <Col/>
      </Row>
      <hr/>
      <Row>
        <Col xs={12} md={12}>
          <h5><b>{t('HOME_CI_005')}</b></h5>
        </Col>
      </Row>
      <Row>
        <Col style={{marginBottom:50}}>
    <Table responsive="sm">
    <thead>
    </thead>
    <tbody>
      <tr>
        <Card className="bg-light text-black">
        <Card.Header>{t('HOME_CI_001')}</Card.Header>
        <Card.Body>
          <Card.Text>
            {t('HOME_CI_002')}
          </Card.Text>
        </Card.Body>
        </Card>
      </tr>
      <tr>
        <Card className="bg-light text-black">
        <Card.Header>{t('HOME_CI_006')}</Card.Header>
        <Card.Body>
          <Card.Text>
            {t('HOME_CI_007')}
          </Card.Text>
        </Card.Body>
        </Card>
      </tr>
      <tr>
        <Card className="bg-light text-black">
        <Card.Header>{t('HOME_CI_008')}</Card.Header>
        <Card.Body>
          <Card.Text>
            {t('HOME_CI_009')}
          </Card.Text>
        </Card.Body>
        </Card>
      </tr>
    </tbody>
  </Table>
        </Col>
      </Row>
    </Container>
  </div>
  );
}