import React, { useState, useMemo, useEffect } from "react";
import "./App.css";
import Routes from "./Routes";
import { LinkContainer } from "react-router-bootstrap";
import { Nav, Navbar, NavItem, NavDropdown, Button, Toast } from "react-bootstrap";
import { AppContext } from "./libs/contextLib";
import { useHistory } from "react-router-dom";
import axiosInstance from "./axiosApi";
import LangDropdown from "./components/LangDropdown";
import { getToken, onMessageListener } from "./firebase";
import axios from 'axios'
import Logo from './images/erplogo_rouded.png';
import { useTranslation } from 'react-i18next';
import { BsFillEyeFill } from "react-icons/bs";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { BsFillAlarmFill } from "react-icons/bs";
import config from "./config";
import { differenceInMinutes } from "date-fns";
import SubNavigation from "./components/SubNavigation";
import ServiceWorkerWrapper from "./components/ServiceWorkerWrapper";

function App() {
    
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState('en');

  const [routeAppContext, setRouteAppContext] = useState('');
  const [strStationAppContext, setStrStationAppContext] = useState('');
  const [endStationAppContext, setEndStationAppContext] = useState('');
  const [strSingleDateAppContext, setStrSingleDateAppContext] = useState('');
  const [endSingleDateAppContext, setEndSingleDateAppContext] = useState('');
  const [strRoundDateAppContext, setStrRoundDateAppContext] = useState('');
  const [endRoundDateAppContext, setEndRoundDateAppContext] = useState('');
  const [chargeAppContext, setChargeAppContext] = useState('');
  const [ticketGubunAppContext, setTicketGubunAppContext] = useState("S");
  const [carAppContext, setCarAppContext] = useState('');
  const [carRoundAppContext, setCarRoundAppContext] = useState('');
  const [currentLocation, setCurrentLocation] = useState('/');
  const [routeMap, setRouteMap] = useState([]);
  
  const providerValue = useMemo(() => ({
        isAuthenticated, userHasAuthenticated,
        user, setUser,
        routeAppContext, setRouteAppContext,
        strStationAppContext, setStrStationAppContext,
        endStationAppContext, setEndStationAppContext,
        strSingleDateAppContext, setStrSingleDateAppContext,
        endSingleDateAppContext, setEndSingleDateAppContext,
        strRoundDateAppContext, setStrRoundDateAppContext,
        endRoundDateAppContext, setEndRoundDateAppContext,
        chargeAppContext, setChargeAppContext,
        ticketGubunAppContext, setTicketGubunAppContext,
        carAppContext, setCarAppContext,
        carRoundAppContext, setCarRoundAppContext,
        currentLocation, setCurrentLocation,
        routeMap, setRouteMap,
  }), [isAuthenticated, user, routeAppContext, strStationAppContext, endStationAppContext, strSingleDateAppContext, endSingleDateAppContext, strRoundDateAppContext, endRoundDateAppContext, chargeAppContext, ticketGubunAppContext, carAppContext, carRoundAppContext, currentLocation, routeMap]);
  
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});
  const [isTokenFound, setTokenFound] = useState(false);
  const [payload, setPayload] = useState(null);
  const [lapseMin, setLapseMin] = useState("");

  getToken(setTokenFound);

  onMessageListener().then(payload => {
      if (user !== null) {
        setShow(true);
    } else {
        setShow(false);
    }
    setNotification({title: payload.notification.title, body: payload.notification.body})
    setPayload(payload);
    setLapseMin("just now");
    //console.log(payload);
  }).catch(err => console.log('failed: ', err));
  
  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    axios.post(`${config.DJANGO_URL}/accounts/token/verify/`, {
                token: localStorage.getItem("access_token"),
    }).then(function(response) {
        userHasAuthenticated(true);
        setUser(JSON.parse(localStorage.getItem("access_user")));
    }).catch(function (error) {
        //console.log(error);
    });
    setIsAuthenticating(false);
  }
  
  function handleLogout() {
    // add sign out login...
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_user');
    userHasAuthenticated(false);
    setUser(null);
    history.push("/");
  }
  
  const onChange = event => {
    //console.log(event);
    setSelectedValue(event.target.value );
  }
  
  const handleSelect = (eventKey) => {
    if (eventKey === 'en' || eventKey === 'kr') {
      //console.log('A key was pressed', eventKey);
      setSelectedLang(eventKey);
      i18n.changeLanguage(eventKey);
    }
  }
  
  const onClickToast = () => {
      if (user !== null) {
        setShow(true);
      } else {
        setShow(false);
      }
      
      if (payload !== null) {
        setLapseMin(differenceInMinutes(new Date(), new Date(payload.data.crtdate)));
      }
  }
  
  return (
   !isAuthenticating && (
    <div className="App container-fluid py-3">
      <Toast bg="dark" onClose={() => setShow(false)} show={show} delay={5000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{notification.title}</strong>
            <small>{lapseMin}{lapseMin === 'just now' ? '' : '분전'}</small>
          </Toast.Header>
          <Toast.Body className="text-white">{notification.body}</Toast.Body>
      </Toast>
      <Navbar fluid collapseOnSelect  expand="md" className="mb-3 navbar-dark bg-dark px-2">
        <LinkContainer to="/">
          <Navbar.Brand className="font-weight-bold">
            <img
            src={Logo}
            width="35"
            height="22"
            className="d-inline-block"
            />
            {'  '} {t('HOME_TITLE')} {'   '} 
            <Button variant="dark" onClick={onClickToast}><BsFillAlarmFill /></Button>
             {'   '}
            {isTokenFound && <BsFillEyeFill />}
            {!isTokenFound && <BsFillEyeSlashFill />}
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav activeKey={window.location.pathname} pullRight onSelect={handleSelect}>
          { ((user !== null) ? user.is_staff : false) ? (
          <>
            {isAuthenticated ? (
              <>
                <LinkContainer to="/settings">
                  <Nav.Link>{t('SETTING')}</Nav.Link>
                </LinkContainer>
                <Nav.Link onClick={handleLogout}>LOGOUT</Nav.Link>
              </>
            ) : (
            <>
              <LinkContainer to="/signupbasic">
                <Nav.Link>{t('SIGNUP')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/loginbasic">
                <Nav.Link>{t('LOGIN')}</Nav.Link>
              </LinkContainer>
            </>
            )}
          </>
          ) : (
          <>
            {isAuthenticated ? (
              <>
                <Nav.Link onClick={handleLogout}>LOGOUT</Nav.Link>
              </>
            ) : (
            <>
              <LinkContainer to="/signupbasic">
                <Nav.Link>{t('SIGNUP')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/loginbasic">
                <Nav.Link>{t('LOGIN')}</Nav.Link>
              </LinkContainer>
            </>
            )}
          </>
          )}
            <LangDropdown/>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {'  '}{ (user !== null) ? ( user.nickname + ' ' + t('LOGINDISPLAY') ) : ''} {isAuthenticated}
      { isAuthenticated ? <SubNavigation currentLocation={currentLocation} /> : "" }
      <Navbar fixed="bottom" expand="md" className="navbar-dark bg-light px-2">
        <div className="col-sm-4">
        </div>
        <div className="col-sm-4">
          <div class="text-center">
            E-mail: <a href="mailto:info.lukesoft@gmail.com">info.lukesoft@gmail.com</a> <br/>
            <a href="https://www.lukesoft.kr/"> lukesoft.co, ltd.</a> ⓒ 2021-{new Date().getFullYear()}, {t('VERSION')}
          </div>
        </div>
        <div className="col-sm-4">
        </div>
      </Navbar>
      <AppContext.Provider value={providerValue}>
        <ServiceWorkerWrapper />
        <Routes />
      </AppContext.Provider>
    </div>
   )
  );
}

export default App;

