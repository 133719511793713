import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Signup.css";
import axiosInstance from "../axiosApi";
import axios from 'axios'
import { Nav, Accordion, Stack, Form, Container, Row, Col } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";
import kakaoLogo from "../images/kakao_logo.png";
import naverLogo from "../images/naver_logo.png";
import { KAKAO_AUTH_URL } from "../components/OAuth";
import { useTranslation } from 'react-i18next';
import config from "../config";
import TermsOfService from "../components/TermsOfService";
import TermsOfPrivacy from "../components/TermsOfPrivacy";
import ToggleSwitch from "../components/ToggleSwitch";

const FlexContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 48px);
	grid-column-gap: 8px;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
`;

const URLShareButton = styled.button`
	width: 48px;
	height: 48px;
	color: white;
	border-radius: 24px;
	border: 0px;
	font-weight: 800;
	font-size: 18px;
	cursor: pointer;
	background-color: #9BC59D;
	&:hover {
		background-color: #91CB3E;
	}
`;

const NaverShareButton = styled.a`
    cursor: pointer;
    border-radius: 24px;
	border: 0px;
    &:hover {
		background-color: #4177c6;
	}
  `;
  
const KakaoShareButton = styled.a`
    cursor: pointer;
    border-radius: 24px;
	border: 0px;
    &:hover {
		background-color: #4177c6;
	}
  `;

const KakaoIcon = styled.img`
	width: 48px;
	height: 48px;
	border-radius: 24px;
  `;

export default function SignupSocial() {
  const { t } = useTranslation();
  const [fields, handleFieldChange] = useFormFields({
    authcode: "",
  });
  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const { userHasAuthenticated } = useAppContext();
  const { user, setUser } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(t('AUTHMSG'));
  const [isConfirm, setIsConfirm] = useState(true);
  const [isSwitchOnService, setIsSwitchOnService] = useState(false);
  const [isSwitchOnPrivate, setIsSwitchOnPrivate] = useState(false);
  const [isSwitchOnAgeLimit, setIsSwitchOnAgeLimit] = useState(false);

  const onSwitchActionService = () => {
    setIsSwitchOnService(!isSwitchOnService);
    //console.log(isSwitchOnService);
  };

  const onSwitchActionPrivate = () => {
    setIsSwitchOnPrivate(!isSwitchOnPrivate);
    //console.log(isSwitchOnPrivate);
  };

  const onSwitchActionAgeLimit = () => {
    setIsSwitchOnAgeLimit(!isSwitchOnAgeLimit);
    //console.log(isSwitchOnAgeLimit);
  };

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    
    if (!isConfirm) {
      axios.post(`${config.DJANGO_URL}/accounts/checksignup/`, {
                authcode: fields.authcode,
      }).then(function(response) {
        if (response.data.access === 'ok') {
          setIsConfirm(true);
          setMessage(t('AUTHOKMSG'));
        } else {
          setMessage(t('AUTHFAILMSG'));
        }
        setIsLoading(false);
      }).catch(function (error) {
        if (typeof error === 'object') {
          //console.log(error.response);
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          }
        }
        onError(t('AUTHFAILMSG'));
        userHasAuthenticated(false);
        setIsLoading(false);
      });
    } else {
      axios.post(`${config.DJANGO_URL}/accounts/login/`, {
                email: fields.email,
                password: fields.password
      }).then(function(response) {

        axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access_token;
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('refresh_token', response.data.refresh_token);
        localStorage.setItem('access_user', JSON.stringify(response.data.user));
        setUser(response.data.user);
        userHasAuthenticated(true);
        history.push("/");
        
      }).catch(function (error) {
        if (typeof error === 'object') {
          //console.log(error.response);
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          }
        }
        onError('AUTHFAILMSG2');
        setMessage(t('AUTHFAILMSG2'));
        userHasAuthenticated(false);
        setIsLoading(false);
      });
    }
  }

  return (
    <Stack gap={3}>
      <div className="bg-light border">
        <Nav sticky="top">
              <LinkContainer to="/signupsocial">
                  <Nav.Link>SNS {t('SIGNUP')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/signupbasic">
                  <Nav.Link>E-mail {t('SIGNUP')}</Nav.Link>
              </LinkContainer>
        </Nav>
      </div>
      {/*
      <div className="bg-light border">
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="authcode" size="lg">
            <Form.Label>{t('AUTHCODE')}</Form.Label>
            <Form.Control
              type="text"
              onChange={handleFieldChange}
              value={fields.authcode}
            />
          </Form.Group>
          <LoaderButton
            block
            size="lg"
            type="submit"
            variant="dark"
            isLoading={isLoading}
            disabled={isConfirm}
          >
            {t('AUTHVERIFY')}
          </LoaderButton>
        </Form>
      </div>
      */}
      <div>
      {(isConfirm && isSwitchOnService && isSwitchOnPrivate && isSwitchOnAgeLimit) ? (
        <FlexContainer>
			        <GridContainer>
				        <URLShareButton ></URLShareButton>
				        <KakaoShareButton href={KAKAO_AUTH_URL} >
		              <KakaoIcon src={kakaoLogo}></KakaoIcon>
		            </KakaoShareButton>
				        <URLShareButton ></URLShareButton>
				    </GridContainer>
				</FlexContainer>
			) : (
			  <>
			  {message}
			  </>
			)}
      </div>
      <div className="bg-light border">
        <Container>
          <Row className="justify-content-md-center">
            <Col sm>
              <ToggleSwitch label={t('TERMSOFAGE')} isChecked={isSwitchOnAgeLimit} onChange={onSwitchActionAgeLimit}/>
            </Col>
            <Col sm>
              <ToggleSwitch label={t('TERMSOFSERVICE')} isChecked={isSwitchOnService} onChange={onSwitchActionService}/>
            </Col>
            <Col sm>
              <ToggleSwitch label={t('TERMSOFPRIVACY')} isChecked={isSwitchOnPrivate} onChange={onSwitchActionPrivate}/>
            </Col>
          </Row>
        </Container>
        <Accordion alwaysOpen="False">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {t('TERMSOFSERVICE')}
            </Accordion.Header>
            <Accordion.Body>
              <TermsOfService />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              {t('TERMSOFPRIVACY')}
            </Accordion.Header>
            <Accordion.Body>
              <TermsOfPrivacy />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>  
      </div>
      <div>
        <br /><br /><br />
      </div>
    </Stack>
  );
}