import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Stack } from 'react-bootstrap';
import { useParams, useHistory, Link } from "react-router-dom";
import axiosInstance from "../axiosApi";
import { onError } from "../libs/errorLib";
import moment from "moment";
import 'moment/locale/ko';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useTranslation } from 'react-i18next';
import ToggleSwitch from "../components/ToggleSwitch";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { IconContext } from "react-icons";
import TableDateRange from "./TableDateRange";
import * as dateFns from "date-fns";

function DrivingRecord() {
  const [bookingCar, setBookingCar] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();
  const [stateOptions, setStateOptions] = useState([
    { name: t('INGSTATUS_001'),id: "A"},
    { name: t('INGSTATUS_002'),id: "B"},,
    { name: t('INGSTATUS_003'),id: "C"},
    { name: t('INGSTATUS_004'),id: "D"},
    { name: t('INGSTATUS_005'),id: "E"},
    { name: t('INGSTATUS_006'),id: "F"},
    { name: t('INGSTATUS_007'),id: "G"},
    { name: t('INGSTATUS_008'),id: "H"},
  ]);
  const [startDate, setStartDate] = useState(new Date()); 
  const [endDate, setEndDate] = useState(new Date()); 
  const [fileName, setFileName] = useState("");
  
  const columns = [
    {
        dataField: "s_gubun",
        text: '서비스명 및 노선명',
    },
    {
        dataField: "s_station_lng",
        text: '승차지경도'
    },
    {
        dataField: "s_station_lat",
        text: '승차지위도'
    },
    {
        dataField: "e_station_lng",
        text: '하차지경도'
    },
    {
        dataField: "e_station_lat",
        text: '하차지위도'
    },
    {
        dataField: "rs_date",
        text: '승차시간'
    },
    {
        dataField: "re_date",
        text: '하차시간'
    },
    {
        dataField: "leadtime",
        text: '이용시간(분)'
    },
    {
        dataField: "rundistance",
        text: '이용거리(km)'
    },
    {
        dataField: "charge",
        text: '이용요금(원)'
    }
  ];
  
  const MyExportData = (props) => {
    return (
      <IconContext.Provider
          value={{style: { color: '#A4BEF3', fontSize: '30px' }}}
        >
          <div>
            <FaCloudDownloadAlt onClick={ props.onClick } />
            {" "}To {props.type}
          </div>
      </IconContext.Provider>
        //<button className="btn btn-success" onClick={ handleClick }>다운로드 CSV</button>
    );
  };

  const products = [
    { id: 1, s_station: "디지스트 R4", s_date: "2021-11-30T11:15:00", e_station: "유가우체국", ing_status: "배차진행" },
    { id: 2, s_station: "디지스트 R4", s_date: "2021-11-30T11:15:00", e_station: "유가우체국", ing_status: "차량이동" },
    { id: 3, s_station: "디지스트 R4", s_date: "2021-11-30T11:15:00", e_station: "유가우체국", ing_status: "승차대기" }
  ];

  useEffect(() => {
    onLoad();
  }, [startDate, endDate]);
  
  async function onLoad() {
    setBookingCar([]);
    //console.log(startDate);
    //console.log(endDate);
    try {
      axiosInstance.get('/robotaxi/bookingcar/drivingrecord/', {
          params:{ 
              ing_status: 'F',
              rs_date:dateFns.format(startDate,"yyyy-MM-dd'T'00:00:00"),
              re_date:dateFns.format(dateFns.add(endDate, { days: 1 }),"yyyy-MM-dd'T'00:00:00"),
          }
      }).then(function(response) {
          let filename;
          response.data.map( (data_row) => {
              let book = {};
              
              book['s_gubun'] = '노선여객운송서비스('.concat(data_row.route.routename,')')
              book['s_station_lng'] = data_row.s_station.site_lng
              book['s_station_lat'] = data_row.s_station.site_lat
              book['e_station_lng'] = data_row.e_station.site_lng
              book['e_station_lat'] = data_row.e_station.site_lat
              book['rs_date'] = moment(new Date(data_row.rs_date)).format("yyyy-MM-DD HH:mm:ss")
              book['re_date'] = moment(new Date(data_row.re_date)).format("yyyy-MM-DD HH:mm:ss")
              book['leadtime'] = data_row.leadtime
              book['rundistance'] = data_row.rundistance
              book['charge'] = data_row.charge
              setBookingCar(bookingCar => [...bookingCar, book])
              
              filename = '실적증빙_노선여객운송서비스('.concat(data_row.route.routename,')_데이터기간(',dateFns.format(startDate,"yyyy-MM-dd"),'~',dateFns.format(endDate,"yyyy-MM-dd"),')')
          });
          setFileName(filename);
      }).catch(function (error) {
        if ( error.response.status === 444 ) {
          onError(error.response.data.message);
        } else {
          onError(error);
        }
      }); 
    } catch (e) {
      onError(e);
    }
  }

  function onSelectRow(row, isSelected, e) {
    if (isSelected) {
        console.log(`You just selected '${row['s_date']}'`);
    }
  };
  
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group" role="group">
      {
        options.map((option) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={ option.text }
              type="button"
              onClick={ () => onSizePerPageChange(option.page) }
              className={ `btn ${isSelect ? 'btn-dark' : 'btn-secondary'}` }
            >
              { option.text }
            </button>
          );
        })
      }
    </div>
  );

  const options = {
    sizePerPageRenderer
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob(["\ufeff"+data], { type: fileType + '; charset=utf-8'})
  
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }
  
  const exportToJson = e => {
    e.preventDefault()
    downloadFile({
      data: JSON.stringify(bookingCar),
      fileName: 'users.json',
      fileType: 'text/json',
    })
  }
  
  const exportToCsv = e => {
    e.preventDefault()
  
    // Headers for each column
    let headers = ['서비스명 및 노선명,승차지경도,승차지위도,하차지경도,하차지위도,승차시간,하차시간,이용시간(분),이용거리(km),이용요금(원)']
  
    // Convert users data to a csv
    let booksCsv = bookingCar.reduce((acc, book) => {
      const { s_gubun, s_station_lng, s_station_lat, e_station_lng, e_station_lat, rs_date, re_date, leadtime, rundistance, charge } = book
      acc.push([s_gubun, s_station_lng, s_station_lat, e_station_lng, e_station_lat, rs_date, re_date, leadtime, rundistance, charge].join(','))
      return acc
    }, [])

    downloadFile({
      data: [...headers, ...booksCsv].join('\n'),
      fileName: fileName.concat('.csv'),
      fileType: 'text/csv',
    })
  }

  const exportToTxt = e => {
    e.preventDefault()
  
    // Headers for each column
    let headers = ['서비스명 및 노선명|승차지경도|승차지위도|하차지경도|하차지위도|승차시간|하차시간|이용시간(분)|이용거리(km)|이용요금(원)']
  
    // Convert users data to a csv
    let booksCsv = bookingCar.reduce((acc, book) => {
      const { s_gubun, s_station_lng, s_station_lat, e_station_lng, e_station_lat, rs_date, re_date, leadtime, rundistance, charge } = book
      acc.push([s_gubun, s_station_lng, s_station_lat, e_station_lng, e_station_lat, rs_date, re_date, leadtime, rundistance, charge].join('|'))
      return acc
    }, [])

    downloadFile({
      data: [...headers, ...booksCsv].join('\n'),
      fileName: fileName.concat('.txt'),
      fileType: 'text/plain',
    })
  }

  async function onChangeStartDate(event) {
    // 현재시간 1시간이후 부터 예약가능시간 적용
    if ( dateFns.isAfter(endDate, new Date(event)) ) {
        setStartDate(startDate => event);
    } else {
        alert("시작일자는 종료일자보다 같거나 작아야 합니다.");
        setStartDate(startDate => new Date());
    }
  }

  async function onChangeEndDate(event) {
    // 현재시간 1시간이후 부터 예약가능시간 적용
    if ( dateFns.isAfter(new Date(event), startDate) ) {
        setEndDate(endDate => event);
    } else {
        alert("종료일자는 시작일자보다 같거나 커야 합니다.");
        setEndDate(endDate => new Date());
    }
  }

  return (
  <Stack gap={3}>
      <div className="bg-light border">
        <div>
        <Container>
            <Row xs="auto">
                <Col>
                    <TableDateRange
                        wrapperClassName="datepicker"
                        selectedStartDate={startDate}
                        onChangeStartDate={onChangeStartDate} 
                        selectedEndDate={endDate}
                        onChangeEndDate={onChangeEndDate} 
                    />
                </Col> 
            </Row>
            <Row xs="auto">
                <Col />
                <Col>
                    <MyExportData onClick={exportToCsv} type='CSV' />
                </Col>
                <Col>
                    <MyExportData onClick={exportToCsv} type='TXT' />
                </Col>
            </Row>
        </Container>
        <BootstrapTable
            bootstrap4
            keyField="id"
            data={bookingCar}         
            columns={columns}
            striped
            hover
            condensed
            bordered={ false }
            pagination={paginationFactory(options)}
        />
        </div>
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default DrivingRecord;