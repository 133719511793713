import { Table, NavLink, Image, Jumbotron, Container, Row, Col, Badge, Card, Carousel, ListGroup } from "react-bootstrap";
import styles from './BusinessInfo.css';
import Green_image from '../images/bg_green_wing_v03_crop.png';
import Gray_image from '../images/bg_gray_wing_v03_crop.png';
import webapplication_img from '../images/webapplication.jpg';
import dashboard_img from '../images/dashboard.jpg';
import email_img from '../images/email.jpg';
import { useTranslation } from 'react-i18next';

export default function BusinessInfo() {
  const { t } = useTranslation();
  
  return (
   <div className="businessinfo_body">
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} md={12} style={{padding:10, marginTop:50, marginBottom:50}}>
          <div><h1><Badge variant="secondary">{t('BUSINESSAREAS')}</Badge></h1></div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Carousel>
            <Carousel.Item>
                <Image src={webapplication_img} thumbnail />
                <ListGroup>
                    <ListGroup.Item>{t('HOME_CI_001')}</ListGroup.Item>
                    <ListGroup.Item>{t('HOME_BI_001')}</ListGroup.Item>
                    <ListGroup.Item>{t('HOME_BI_002')}</ListGroup.Item>
                    <ListGroup.Item>{t('HOME_BI_003')}</ListGroup.Item>
                </ListGroup>
            </Carousel.Item>
            <Carousel.Item>
                <Image src={dashboard_img} thumbnail />
                <ListGroup>
                    <ListGroup.Item>{t('HOME_CI_006')}</ListGroup.Item>
                    <ListGroup.Item>{t('HOME_BI_004')}</ListGroup.Item>
                    <ListGroup.Item>{t('HOME_BI_005')}</ListGroup.Item>
                    <ListGroup.Item>{t('HOME_BI_006')}</ListGroup.Item>
                </ListGroup>
            </Carousel.Item>
            <Carousel.Item>
                <Image src={email_img} thumbnail />
                <ListGroup>
                    <ListGroup.Item>{t('HOME_CI_008')}</ListGroup.Item>
                    <ListGroup.Item>{t('HOME_BI_007')}</ListGroup.Item>
                    <ListGroup.Item>{t('HOME_BI_008')}</ListGroup.Item>
                    <ListGroup.Item>{t('HOME_BI_009')}</ListGroup.Item>
                </ListGroup>
            </Carousel.Item>
        </Carousel>
      </Row>
    </Container>
  </div>
  );
}