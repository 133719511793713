import { Table, NavLink, Image, Jumbotron, Container, Row, Col, Badge } from "react-bootstrap";
import styles from './CompanyContact.css';
import logo_h from '../images/logo_h_lukesoft.png';

export default function CompanyContact() {
  return (
   <>
    
    <Container>
      <Row>
        <Col xs={12} md={12}style={{padding:10, marginTop:50, marginBottom:50}}>
          <div><h1><Badge variant="secondary">Contact</Badge></h1></div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} style={{padding:10}}>
          <Image src={logo_h} rounded />
        </Col>
        <Col xs={12} md={7} style={{padding:10}}>
            <Table responsive="sm">
              <tbody>
                <tr>
                  <td>www.lukesoft.kr</td>
                </tr>
                <tr>
                  <td>E-mail: info.lukesoft@gmail.com</td>
                </tr>
                <tr>
                  <td>COPYRIGHT ⓒ 2021 LukeSoft Co., Ltd. ALL RIGHTS RESERVED</td>
                </tr>
              </tbody>
            </Table>
        </Col>
      </Row>
    </Container>
  </>
  );
}