import React, { useRef, useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import { useTranslation } from 'react-i18next';

function RouteLIneInfo() {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [route, setRoute] = useState(null);
  const [routename, setRoutename] = useState("");
  const [ref_lat, setRef_lat] = useState("");
  const [ref_lng, setRef_lng] = useState("");
  const [defalt_zoom, setDefalt_zoom] = useState("");
  
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  
  useEffect(() => {
    async function onLoad() {
      try {
        axiosInstance.get(`/robotaxi/route/${id}/`).then(function(response) {
          console.log(response.data);
          const route = response.data;
          setRoute(route);
          setRoutename(route.routename);
          setRef_lat(route.ref_lat);
          setRef_lng(route.ref_lng);
          setDefalt_zoom(route.defalt_zoom);
        }).catch(function (error) {
          //console.log(error);
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(error);
          }
        });
      } catch (e) {
        onError(e);
      }
    }
    
    onLoad();
  }, [id]);
  
  function validateForm() {
    return (
      routename.length > 0 &&
      ref_lat.length > 0 &&
      ref_lng.length > 0
    );
  }
  
  function saveRoute(route) {
    axiosInstance.put(`/robotaxi/route/${id}/`, route).then(function(response) {
      return;
    }).catch(function (error) {
      //console.log(error);
      if ( error.response.status === 444 ) {
        onError(error.response.data.message);
      } else {
        onError(error);
      }
      setIsLoading(false);
    }); 
  }
  
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await saveRoute({
        routename: routename,
        ref_lat: ref_lat,
        ref_lng: ref_lng,
        defalt_zoom: defalt_zoom,
      });
    
      history.push("/routeline");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function deleteRoute() {
    return axiosInstance.delete(`/robotaxi/route/${id}/`, route).then(function(response) {
          return;
        }).catch(function (error) {
          //console.log(error);
          onError(error);
          setIsLoading(false);
        }); 
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this note?"
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteRoute();
      
      history.push("/routeline");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }
  
  return (
  <Stack gap={3}>
      <div className="bg-light border">
        
        <div className="Notes">
          {route && (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="routename">
                <Form.Label>노선명</Form.Label>
                <Form.Control
                  type="text"
                  value={routename}
                  onChange={(e) => setRoutename(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="ref_lat">
                <Form.Label>위도</Form.Label>
                <Form.Control
                  type="text"
                  value={ref_lat}
                  onChange={(e) => setRef_lat(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="ref_lng">
                <Form.Label>경도</Form.Label>
                <Form.Control
                  type="text"
                  value={ref_lng}
                  onChange={(e) => setRef_lng(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="defalt_zoom">
                <Form.Label>지도Zoom값</Form.Label>
                <Form.Control
                  type="text"
                  value={defalt_zoom}
                  onChange={(e) => setDefalt_zoom(e.target.value)}
                />
              </Form.Group>
              <LoaderButton
                block
                size="lg"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Save
              </LoaderButton>
              <LoaderButton
                block
                size="lg"
                variant="danger"
                onClick={handleDelete}
                isLoading={isDeleting}
              >
                Delete
              </LoaderButton>
            </Form>
          )}
        </div>
        
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default RouteLIneInfo;