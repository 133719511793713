import React, { useState, useEffect } from "react";
import { Nav, Navbar, Stack, Button, Container, Row, Col } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import {Html5QrcodeScanner} from "html5-qrcode";
import { useTranslation } from 'react-i18next';
import moment from "moment";

function QrScan() {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [message, setMessage] = useState(t('SCAN_READY'));
  const [qrcode, setQrcode] = useState("");
  const [sDate, setSDate] = useState("");
  const [sStation, setSStation] = useState("");
  const [eStation, setEStation] = useState("");
  const [booker, setBooker] = useState("");
  var chkLoading = false;
  
  useEffect(() => {
    if (Html5QrcodeScanner) {
      // Creates anew instance of `HtmlQrcodeScanner` and renders the block.
      let html5QrcodeScanner = new Html5QrcodeScanner(
        "reader",
        { fps: 10, qrbox: {width: 250, height: 250} },
        /* verbose= */ false);
      html5QrcodeScanner.render(onScanSuccess,onScanFailure);
    }
  }, [Html5QrcodeScanner]);
  
  async function updateBoardingOn(qrUrl, book) {
    return axiosInstance.put(qrUrl, book).then(function(response) {
          setQrcode(qrUrl);
          if (book.ing_status === response.data.ing_status) {
            setMessage("승차처리가 완료 되었습니다.")
          } else {
            setMessage("처리에 실패하였습니다");
          }
          return;
        }).catch(function (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            setMessage("처리에 실패하였습니다");
          }
          setIsLoading(false);
        }); 
  }

  async function updateBoardingOff(qrUrl, book) {
    return axiosInstance.put(qrUrl, book).then(function(response) {
          setQrcode(qrUrl);
          if (book.ing_status === response.data.ing_status) {
            setMessage("하차처리가 완료 되었습니다.")
          } else {
            setMessage("처리에 실패하였습니다");
          }
          return;
        }).catch(function (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            setMessage("처리에 실패하였습니다");
          }
          setIsLoading(false);
        }); 
  }
  
  async function handleUpdate(data) {
    let qrUrl = data['url'];
    try {
      axiosInstance.get(qrUrl).then(function(response) {
        setSDate(moment(new Date(response.data.s_date)).format("MM.DD(ddd) HH:mm"));
        setSStation(response.data.s_station.stationname);
        setEStation(response.data.e_station.stationname);
        setBooker(response.data.user.email);
        if (response.data.ing_status === 'E') {
          updateBoardingOff(qrUrl, {
            ing_status: 'F',
          });
        } 
        
        if (response.data.ing_status < 'E') {
          updateBoardingOn(qrUrl, {
            ing_status: 'E',
          });
        }
      }).catch(function (error) {
          //console.log(error);
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(error);
          }
          setIsLoading(false);
      }); 
      
    } catch (e) {
      onError(e);
    }
  };
  
  async function onScanSuccess(decodedText, decodedResult) {
    // handle the scanned code as you like, for example:
    //console.log(`Code matched = ${decodedText}`, decodedResult);
    var json_obj = JSON.parse(decodedText);

    if (!chkLoading) {
      chkLoading = true;
      
      setMessage("처리중입니다");  
      await handleUpdate(json_obj);

      setTimeout(()=>{ 
        chkLoading = false;
        setMessage("스캔 대기중입니다."); 
      }, 7000);
    }
  }
  
  function onScanFailure(error) {
    // handle scan failure, usually better to ignore and keep scanning.
    // for example:
    //setMessage(`Code scan error = ${error}`);
  }

  async function handleTest() {
    let decodedText={};

    decodedText = `{"url":"/robotaxi/bookingcar/3/"}`;
    var json_obj = JSON.parse(decodedText);

    if (!chkLoading) {
      chkLoading = true;
      
      setMessage("처리중입니다");  
      await handleUpdate(json_obj);

      setTimeout(()=>{ 
        chkLoading = false;
        setMessage("스캔 대기중입니다."); 
      }, 7000);
    }
  }
  
  return (
  <Stack gap={3}>
      {t('MSG_0004')}
      <div className="bg-light border">
        <h5>QR Code : {message}</h5>
      </div>
      <div id='reader'></div>
      <div className=" border">
        <Container>
          <Row>
            {t('DATE_BOOK')}: {sDate}
          </Row>
          <Row>
            {t('GETONSPOT')}: {sStation}
          </Row>
          <Row>
            {t('GETOFFSPOT')}: {eStation}
          </Row>
          <Row>
            {t('BOOKER')}: {booker}
          </Row>
        </Container>
      </div>
      {/*}
      <div className="bg-light border">
        <Button variant="primary" onClick={handleTest}>테스트</Button>
      </div>
      */}
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default QrScan;