import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import { Nav, Navbar, Stack, Button, Alert, Modal } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import Logo from '../images/favicon-32x32.png';
import { useTranslation } from 'react-i18next';
import * as dateFns from "date-fns";
import LoaderButton from "../components/LoaderButton";
import config from "../config";

function BillCompleteModal(props) {
  const { t } = useTranslation();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('BILL_TITLE')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{t('BILL_STATUS')}</h4>
        <p>
          {t('BILL_RESULT_OK')}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function BoardingTicket() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [routes, setRoutes] = useState([]);
  const { id } = useParams();
  const [bookingCar, setBookingCar] = useState(null);
  const { t } = useTranslation();
  const [isBillingKey, setIsBillingKey] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [tempUser, setTempUser] = useState(JSON.parse(localStorage.getItem("access_user")));
  
  useEffect(() => {
    async function onLoad() {
      try {
        axiosInstance.get(`/accounts/user/${tempUser.pk}/`).then(function(response) {
          if (response.data.status === 'CARD') {
            setIsBillingKey(true);
          } else {
            setIsBillingKey(false);
          }
        }).catch(function (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(t('PROCESS_FAIL'));
          }
        });

        axiosInstance.get(`/robotaxi/bookingcar/${id}/`).then(function(response) {
          setBookingCar(response.data);
        }).catch(function (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            onError(error);
          }
        });
      } catch (e) {
        onError(e);
      }
    }
    
    onLoad();
  }, [id]);
  
  async function cancelApproval(book) {
    try {
      const response = await axiosInstance.put(`/robotaxi/bookingcar/${id}/`, book);
      return;
    } catch(error) {
      if ( error.response.status === 444 ) {
        onError(error.response.data.message);
      } else {
        onError("결제취소에 실패하였습니다.");
      }
    }; 
  }
  
  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "해당 결제건을 취소하시겠습니까?"
    );

    if (!confirmed) {
      return;
    }
    setIsLoading(true);

    try {
      await cancelApproval({
        ing_status: 'G',
      });
      alert("정상적으로 취소되었습니다.")
      setIsLoading(false);
      history.push("/bookingcar");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  };

  async function handleBookCancel(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "해당 예약건을 취소하시겠습니까?"
    );

    if (!confirmed) {
      return;
    }
    setIsLoading(true);

    try {
      const response = await axiosInstance.delete(`/robotaxi/bookingcar/${id}/`);
      alert("정상적으로 취소되었습니다.")
      setIsLoading(false);
      history.push("/bookingcar");
    } catch(error) {
      if ( error.response.status === 444 ) {
        onError(error.response.data.message);
      } else {
        onError("예약취소에 실패하였습니다.");
      }
      setIsLoading(false);
    }; 
  };

  async function handleApproval(event) {
    event.preventDefault();
    console.log(bookingCar.book_date);
    const confirmed = window.confirm(
      "해당 예약건을 결제하시겠습니까?"
    );

    if (!confirmed) {
      return;
    }

    await handleSubmitCallback();

  };

  async function handleSubmitCallback() {
    let imp_uid = '';
    let pay_amount = 3000;
    var merchant_uid = bookingCar.book_date;

    if (isBillingKey) {
      //등록된 카드로 정기결재 처리
      imp_uid = '';

      try {
        const response = await axiosInstance.post(`/robotaxi/bookingcarpay/`, {
          imp_uid: imp_uid,
          merchant_uid: merchant_uid
        });
        showModal();
      } catch(error) {
        if ( error.response.status === 444 ) {
          onError(error.response.data.message);
        } else {
          onError(t('PROCESS_FAIL'));
        }
      };

    } else {
      // call paymant process
      if (bookingCar.ticket === 'R') {
        pay_amount = 6000;
      } else {
        pay_amount = 3000;
      }
      //Jtnet 결재창 호출
      var IMP = window.IMP;
      IMP.init("imp82530589");

      IMP.request_pay({ // param
        pg : 'jtnet',
        pay_method : 'card', // 'card'만 지원됩니다.
        merchant_uid: merchant_uid, // 상점에서 관리하는 주문 번호
        name : '주문명: Raxi자율주행차운임',
        amount : pay_amount,
        buyer_email : tempUser.email,
        buyer_name : '',
        buyer_tel : '',
        m_redirect_url : `${config.REDIRECT_URI_JTNET_GENERAL}`
      }, function (rsp) { // callback
          //console.log(rsp);
          if (rsp.success) {
            imp_uid = rsp.imp_uid;
            axiosInstance.post(`/robotaxi/bookingcarpay/`, {
              imp_uid: imp_uid,
              merchant_uid: merchant_uid
            }).then(function(response) {
              showModal();
            }).catch(function (error) {
              //deletePayError(imp_uid, merchant_uid, error);
              setIsLoading(false);
              history.push("/bookingcar");
              onError("결재가 완료되지 않았습니다. 예약내역에서 결재 또는 예약취소 해주세요.(pc3)");
            });
          } else {
            //deletePayError(imp_uid, merchant_uid, rsp);
            setIsLoading(false);
            history.push("/bookingcar");
            onError("결재가 완료되지 않았습니다. 예약내역에서 결재 또는 예약취소 해주세요.(pc4)");
          }
      }); 
    }
  }
  
  // 오류발생하여 결재처리시 예외처리로 해결함_2022.05.14
  async function deletePayError(imp_uid, merchant_uid, error_msg) {
    try {
      const response = await axiosInstance.post(`/robotaxi/bookingcarpayerror/`, {
        imp_uid: imp_uid,
        merchant_uid: merchant_uid
      });
      alert(error_msg);
      history.push("/bookingcar");
    } catch(error) {
      if ( error.response.status === 444 ) {
        onError(error.response.data.message);
      } else {
        onError(t('PROCESS_FAIL'));
      }
    };
  }
  
  const downloadQRCode = () => {
    const qrCodeURL = document.getElementById('qrCodeEl')
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(qrCodeURL)
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }
  
  function validateForm() {
    let minutes = 0;

    if (!bookingCar) {
      return false;
    }

    minutes = dateFns.differenceInMinutes(new Date(), new Date(bookingCar.s_date))
    return (
      (Math.abs(minutes) > 10) &&
      (bookingCar.ing_status !== 'G') &&
      dateFns.isAfter(new Date(bookingCar.s_date), new Date())
    );
  }

  function showModal() {
    setModalShow(true);
  }
  
  function hideModal() {
    setModalShow(false);
    history.push("/bookingcar");
  }

  return (
  <Stack gap={3}>
      <div className="bg-light border">
        <h1>Boarding Ticket</h1>
      </div>
      <div className="bg-light border">
        { (bookingCar&&bookingCar.ing_status === 'J') ? (
          <>
            <LoaderButton
                block
                variant="danger"
                onClick={handleApproval}
                isLoading={isLoading}
            >
                {t('APPROVAL')}
            </LoaderButton>
            <LoaderButton
                block
                variant="danger"
                onClick={handleBookCancel}
                isLoading={isLoading}
            >
                {t('BOOKCANCEL')}
            </LoaderButton>
          </>
        ) : (
          <>
            <LoaderButton
                block
                variant="danger"
                disabled={!validateForm()}
                onClick={handleDelete}
                isLoading={isLoading}
            >
                {t('BILLCANCEL')}
            </LoaderButton>
          </>
        )}
        <hr />
        <h5>예약출발시간 10분 전까지 취소가 가능합니다.</h5>
      </div>
      <div className="bg-light border">
        {/*
          <QRCode
            className="center"
            value={`{"url":"/robotaxi/bookingcar/${id}/"}`}
            size={256}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"L"}
            includeMargin={false}
            renderAs={"svg"}
            imageSettings={{
                src: "https://static.zpao.com/favicon.png",
                x: null,
                y: null,
                height: 42,
                width: 42,
                excavate: true,
            }}
        />
        */}

        <QRCode
            id="qrCodeEl"
            value={`{"url":"/robotaxi/bookingcar/${id}/"}`}
            size={256}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"L"}
            includeMargin={true}
        />

      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default BoardingTicket;