import React, { useState, useEffect } from "react";
import { Nav, Navbar, Stack, Button, Container, Row, Col } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import {Html5QrcodeScanner} from "html5-qrcode";
import { useTranslation } from 'react-i18next';
import moment from "moment";

function BoardingQrScan() {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [message, setMessage] = useState(t('SCAN_READY'));
  const [qrcode, setQrcode] = useState("");
  const [sDate, setSDate] = useState("");
  const [sStation, setSStation] = useState("");
  const [eStation, setEStation] = useState("");
  const [booker, setBooker] = useState("");
  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }
  var chkLoading = false;

  useEffect(() => {
      if (Html5QrcodeScanner) {
        // Creates anew instance of `HtmlQrcodeScanner` and renders the block.
        let html5QrcodeScanner = new Html5QrcodeScanner(
          "reader",
          { fps: 10, qrbox: {width: 250, height: 250} },
          /* verbose= */ false);
        html5QrcodeScanner.render(onScanSuccess,onScanFailure);
      }
  }, [Html5QrcodeScanner]);
  
  async function updateBoardingOn(qrUrl, book) {
    return axiosInstance.put(qrUrl, book).then(function(response) {
          setQrcode(qrUrl);
          if (book.ing_status === response.data.ing_status) {
            setMessage("승차처리가 완료 되었습니다.")
          } else {
            setMessage(t('PROCESS_FAIL'));
          }
          return;
        }).catch(function (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            setMessage(t('PROCESS_FAIL'));
          }
          setIsLoading(false);
        }); 
  }

  async function updateBoardingOff(qrUrl, book) {
    return axiosInstance.put(qrUrl, book).then(function(response) {
          setQrcode(qrUrl);
          if (book.ing_status === response.data.ing_status) {
            setMessage("하차처리가 완료 되었습니다.")
          } else {
            setMessage(t('PROCESS_FAIL'));
          }
          return;
        }).catch(function (error) {
          if ( error.response.status === 444 ) {
            onError(error.response.data.message);
          } else {
            setMessage(t('PROCESS_FAIL'));
          }
          setIsLoading(false);
        }); 
  }
  
  async function handleUpdate(data) {
    let car_id = data['id'];
    let qrUrl = "";
    try {
      axiosInstance.get('/robotaxi/bookingcar/result/',{
                    params: {id: car_id}
      }).then(function(response) {
          setSDate(moment(new Date(response.data.s_date)).format("MM.DD(ddd) HH:mm"));
          setSStation(response.data.s_station.stationname);
          setEStation(response.data.e_station.stationname);
          setBooker(response.data.user.email);
          qrUrl = `/robotaxi/bookingcar/${response.data.id}/`
          if (response.data.ing_status === 'E') {
            updateBoardingOff(qrUrl, {
              ing_status: 'F',
            });
          }
        
          if (response.data.ing_status < 'E') {
            updateBoardingOn(qrUrl, {
              ing_status: 'E',
            });
          }
      }).catch(function (error) {
        if ( error.response.status === 444 ) {
          onError(error.response.data.message);
        } else {
          setMessage("예약내역이 없습니다. 차량명:" + data['carname']);
          //onError(error);
        }
      }); 
      
    } catch (e) {
      onError(e);
    }
  };

  async function onScanSuccess(decodedText, decodedResult) {
    // handle the scanned code as you like, for example:
    //setMessage(`Code scan = ${decodedText}`);
    var json_obj = JSON.parse(decodedText);

    if (!chkLoading) {
      chkLoading = true;
      
      setMessage("처리중입니다");  
      await handleUpdate(json_obj);

      setTimeout(()=>{ 
        chkLoading = false;
        setMessage(t('MSG_0001')); 
      }, 7000);
    }
    //console.log(data.substr(1,8));
    //if (data.substr(1,8) === 'robotaxi') {
    //  setBoarding();
    //}
  }
  
  function onScanFailure(error) {
    // handle scan failure, usually better to ignore and keep scanning.
    // for example:
    //setMessage(`Code scan error = ${error}`);
  }
  
  async function handleTest() {
    let decodedText={};

    decodedText = '{"id":"1", "carname":"투산1호"}';
    var json_obj = JSON.parse(decodedText);

    if (!chkLoading) {
      chkLoading = true;
      
      setMessage("처리중입니다");  
      await handleUpdate(json_obj);

      setTimeout(()=>{ 
        chkLoading = false;
        setMessage(t('MSG_0001')); 
      }, 9000);
    }
  }

  return (
  <Stack gap={3}>
      {t('BOARDING_INFO')}
      <div className="bg-light border">
        <h5>QR Code : {message}</h5>
      </div>
      <div id='reader'></div>
      <div className=" border">
        <Container>
          <Row>
            {t('DATE_BOOK')}: {sDate}
          </Row>
          <Row>
            {t('GETONSPOT')}: {sStation}
          </Row>
          <Row>
            {t('GETOFFSPOT')}: {eStation}
          </Row>
          <Row>
            {t('BOOKER')}: {booker}
          </Row>
        </Container>
      </div>
      {/*}
      <div className="bg-light border">
        <Button variant="primary" onClick={handleTest}>테스트</Button>
      </div>
      */}
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default BoardingQrScan;